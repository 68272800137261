export class City {
  constructor(data) {
    this.id = data.id;
    this.uuid = data.uuid;
    this.name = data.name;
    this.position = {
      lat: data.gpsLat,
      getLatitude: () => this.position.lat,
      long: data.gpsLng,
      getLongitude: () => this.position.long,
    };
    this.timezone = data.timezone;
    this.active = !!data.active;
    this.picture = data.picture;
    this.cs_operator_uuid = data.csOperatorUuid;
    this.code = data.code;
    this.radius = data.radius;
  }

  getId() {
    return this.id;
  }

  getUUID() {
    return this.uuid;
  }

  getPosition() {
    return this.position;
  }

  getLatitude() {
    return this.position.lat;
  }

  getLongitude() {
    return this.position.long;
  }

  isActive() {
    return this.active;
  }

  getCSOperatorUUID() {
    return this.cs_operator_uuid;
  }

  static create(attr) {
    return new City(attr);
  }
}
export default City;
