import get from 'lodash/get';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export const ERROR_MESSAGES = {
  generic: 'Error',
  unknown: 'Unknown error',
  bookingIsNotObject: '"booking" argument must be an object',
};

export const errorNotification = (error = {}) => ({
  text: get(error, 'response.data.message') || (error && error.message) || FALLBACK_MESSAGE.error,
});
