import flatMap from 'lodash/flatMap';
import find from 'lodash/find';

export const getVehicleAvailabilityFromData = (vehicleUuid, data) => {
  const {
    locations = [],
    start_availability: startAvailability,
    end_availability: endAvailability,
  } = data;

  const onlyVehicles = flatMap(locations, 'vehicles');
  const matchingVehicle = find(onlyVehicles, ['uuid', vehicleUuid]);

  const { uuid, model, occupation } = matchingVehicle;
  return { uuid, model, occupation, startAvailability, endAvailability };
};
