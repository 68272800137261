import { TYPE } from './_mutations';

export const ACTIONS = {
  setLoaderStatus: 'toggleLoaderStatus',
  resetLoaderStore: 'resetLoaderStore',
};

export default {
  [ACTIONS.setLoaderStatus](context, status) {
    context.commit(TYPE.LOADER_ACTIVE, status);
  },

  [ACTIONS.resetLoaderStore](context) {
    context.commit(TYPE.RESET_STATE);
  },
};
