const getState = () => ({
  employee: {
    business_profile_uuid: null,
  },
  availables: {
    locations: null,
    start_availability: null,
    end_availability: null,
    legal_documents: null,
  },
  previousAvailables: {
    locations: null,
    start_availability: null,
    end_availability: null,
    legal_documents: null,
  },
  loading: false,
  oldValues: {
    city: null,
    profile: null,
    bookingType: null,
    start: null,
    end: null,
    vehicleCategory: null,
    transmission: null,
    fuelType: null,
    seats: null,
    doors: null,
  },
  filters: [],
  filtersAreCollapsed: false,
  numberFiltersNotCollapsed: null,
  openFiltersCollapsedPanel: false,
  beforeActions: null,
  afterActions: null,
  emptyMessage: null,
  waitingGeolocation: false,
});

export const AVAILABILITY_FILTERS = {
  TRANSMISSION: 'transmission',
  FUEL_TYPE: 'fuel_type',
  SEATS: 'seats',
  DOORS: 'doors',
  VEHICLE_CATEGORY: 'vehicle_category',
  BOOKING_MODE: 'booking_mode',
};

export default getState;
