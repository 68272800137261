import map from 'lodash/map';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';

import { external } from '@emobg/web-api-client';
import { MUTATIONS } from './_mutations';
import { AVAILABILITY_FILTERS } from './_state';

export const ACTIONS = {
  toggleAvailabilityLoading: 'toggleAvailabilityLoading',
  addBeforeAvailabilityAction: 'addBeforeAvailabilityAction',
  addAfterAvailabilityAction: 'addAfterAvailabilityAction',
  setAvailabilityResponse: 'setAvailabilityResponse',
  setOldCityValue: 'setOldCityValue',
  setOldProfileValue: 'setOldProfileValue',
  setOldBookingTypeValue: 'setOldBookingTypeValue',
  setOldStartValue: 'setOldStartValue',
  setOldEndValue: 'setOldEndValue',
  resetAvailabilityStore: 'resetAvailabilityStore',
  setEmptyMessageLocations: 'setEmptyMessageLocations',
  setWaitingGeolocation: 'setWaitingGeolocation',
  setAvailabilityFilters: 'setAvailabilityFilters',
  setAvailabilityFiltersDirectly: 'setAvailabilityFiltersDirectly',
  openAvailabilityFilter: 'openAvailabilityFilter',
  resetSelectedFilters: 'resetSelectedFilters',
  setOldAvailabilityFilters: 'setOldAvailabilityFilters',
  resetAllSelectedFilters: 'resetAllSelectedFilters',
  setFilterAreCollapsed: 'setFilterAreCollapsed',
  openMoreFiltersPanel: 'openMoreFiltersPanel',
  applyFilters: 'applyFilters',
  setEmployeeProfile: 'setEmployeeProfile',
  setNumFiltersAreCollapsed: 'setNumFiltersAreCollapsed',
};

export default {
  [ACTIONS.toggleAvailabilityLoading](context, status) {
    let isLoading = status;
    if (isNil(status)) {
      isLoading = !context.state.loading;
    }

    context.commit(MUTATIONS.AVAILABILITY_LOADING, isLoading);
  },

  [ACTIONS.addBeforeAvailabilityAction](context, availabilityAction) {
    context.commit(MUTATIONS.ADD_BEFORE_ACTION, availabilityAction);
  },

  [ACTIONS.addAfterAvailabilityAction](context, availabilityAction) {
    context.commit(MUTATIONS.ADD_AFTER_ACTION, availabilityAction);
  },

  [ACTIONS.setAvailabilityResponse](context, response) {
    context.commit(MUTATIONS.SET_AVAILABILITY_RESPONSE, response);
  },

  [ACTIONS.setOldCityValue](context, value) {
    context.commit(MUTATIONS.SET_OLD_CITY_VALUE, value);
  },

  [ACTIONS.setOldProfileValue](context, value) {
    context.commit(MUTATIONS.SET_OLD_PROFILE_VALUE, value);
  },

  [ACTIONS.setOldBookingTypeValue](context, value) {
    context.commit(MUTATIONS.SET_OLD_BOOKING_TYPE_VALUE, value);
  },

  [ACTIONS.setOldStartValue](context, value) {
    context.commit(MUTATIONS.SET_OLD_START_VALUE, value);
  },

  [ACTIONS.setOldEndValue](context, value) {
    context.commit(MUTATIONS.SET_OLD_END_VALUE, value);
  },

  [ACTIONS.resetAvailabilityStore](context, payload) {
    context.commit(MUTATIONS.RESET_STATE, payload);
  },

  [ACTIONS.setEmptyMessageLocations](context, message) {
    context.commit(MUTATIONS.SET_EMPTY_MESSAGE, message);
  },

  [ACTIONS.setWaitingGeolocation](context, status) {
    context.commit(MUTATIONS.WAITING_GEOLOCATION, status);
  },

  async [ACTIONS.setAvailabilityFilters](context, params) {
    let filters = [];

    const {
      vehicleCategoryFilters,
      commonFilters,
      translatedFilters,
    } = await external.availability.getAvailabilityFilters(params);

    if (vehicleCategoryFilters) {
      const categoryFilters = vehicleCategoryFilters.map(filter => ({
        filterName: filter.filterName,
        opened: false,
        selected: [],
        applied: [],
        options: map(filter.values, value => pick(value, ['name', 'internalName', 'image'])),
      }));

      filters = [...filters, ...categoryFilters];
    }

    if (commonFilters) {
      const vehicleCommonFilters = commonFilters.map(filter => ({
        filterName: filter.filterName,
        opened: false,
        selected: [],
        applied: [],
        options: map(filter.values, value => ({ name: value, internalName: value })),
      }));

      filters = [...filters, ...vehicleCommonFilters];
    }

    if (translatedFilters) {
      const vehicleTranslatedFilters = translatedFilters.map(filter => ({
        filterName: filter.filterName,
        opened: false,
        selected: [],
        applied: [],
        options: map(filter.values, value => pick(value, ['name', 'internalName', 'image'])),
      }));

      filters = [...filters, ...vehicleTranslatedFilters];
    }

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);
  },

  async [ACTIONS.openAvailabilityFilter](context, { filter, opened }) {
    const filters = context.state.filters.map(item => {
      const forceOpened = !isNil(opened) ? opened : !item.opened;
      // If the item is the same of the filter clicked
      // we have to toggle his status
      // else we have to close it
      const same = item.filterName === filter.filterName;
      item.opened = same ? forceOpened : false;
      item.selected = [...item.applied];
      return item;
    });

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);

    // If panel is opened and users clicks in a filter button
    // panel should disappear
    context.commit(MUTATIONS.OPEN_FILTERS_COLLAPSED_PANEL, false);
  },

  async [ACTIONS.resetSelectedFilters](context, filter) {
    const filters = context.state.filters.map(item => {
      const same = item.filterName === filter.filterName;
      item.selected = same ? [] : [...item.selected];
      item.applied = [...item.selected];
      item.opened = false;
      return item;
    });

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);
  },

  async [ACTIONS.applyFilters](context, filter) {
    const filters = context.state.filters.map(item => {
      const same = item.filterName === filter.filterName;
      item.applied = same ? [...filter.selected] : [...item.applied];
      item.opened = false;
      return item;
    });

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);
  },

  [ACTIONS.setOldAvailabilityFilters](context) {
    const vehicleCategory
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.VEHICLE_CATEGORY);
    const transmission
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.TRANSMISSION);
    const fuelType
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.FUEL_TYPE);
    const seats
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.SEATS);
    const doors
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.DOORS);
    const bookingMode
      = context.state.filters.find(i => i.filterName === AVAILABILITY_FILTERS.BOOKING_MODE);

    context.commit(MUTATIONS.SET_OLD_AVAILABILITY_FILTERS, {
      vehicleCategory: vehicleCategory ? [...vehicleCategory.applied] : [],
      transmission: transmission ? [...transmission.applied] : [],
      fuelType: fuelType ? [...fuelType.applied] : [],
      seats: seats ? [...seats.applied] : [],
      doors: doors ? [...doors.applied] : [],
      bookingMode: bookingMode ? [...bookingMode.applied] : [],
    });
  },

  [ACTIONS.resetAllSelectedFilters](context) {
    const filters = context.state.filters.map(item => {
      item.selected = [];
      item.applied = [];
      item.opened = false;
      return item;
    });

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);
  },

  [ACTIONS.setFilterAreCollapsed](context, payload) {
    // When screen resolution changes and filters should not
    // be collapsed, we have to close the panel
    if (!payload) {
      context.commit(MUTATIONS.OPEN_FILTERS_COLLAPSED_PANEL, false);
    }

    // Every time the screen resolution changes,
    // we have to close the panel and the button cards
    const filters = context.state.filters.map(item => {
      item.selected = [...item.applied];
      item.opened = false;
      return item;
    });

    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);

    context.commit(MUTATIONS.SET_FILTERS_ARE_COLLAPSED, payload);
  },

  [ACTIONS.openMoreFiltersPanel](context, payload) {
    const filters = context.state.filters.map(item => {
      item.selected = [...item.applied];
      item.opened = false;
      return item;
    });
    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, filters);

    context.commit(MUTATIONS.OPEN_FILTERS_COLLAPSED_PANEL, payload);
  },
  [ACTIONS.setEmployeeProfile](context, payload) {
    context.commit(MUTATIONS.SET_EMPLOYEE_PROFILE, payload);
  },
  [ACTIONS.setNumFiltersAreCollapsed](context, payload) {
    context.commit(MUTATIONS.SET_NUMBER_FILTERS_NOT_COLLAPSED, payload);
  },
  [ACTIONS.setAvailabilityFiltersDirectly](context, payload) {
    context.commit(MUTATIONS.SET_AVAILABILITY_FILTERS, payload);
  },
};
