import routes from './routes-names';

const InviteFriendView = () => import(/* webpackChunkName: "invite" */'../InviteFriendView/InviteFriendView');

export const InviteFriendRoute = {
  path: '/invite-a-friend',
  name: routes.inviteFriendView,
  component: InviteFriendView,
  meta: {
    requireAuth: true,
  },
};

export default [
  InviteFriendRoute,
];

