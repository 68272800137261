<script>
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import { SIZES } from '@emobg/web-components';
import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';

import AlertComponent from '@/components/Alert/AlertComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'FeedbackModalComponent',
  components: {
    AlertComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      type: String,
      default: SIZES.small,
    },
    image: {
      type: String,
      default: '',
    },
    imageWithPadding: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    extraInfo: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    primaryCallToActionText: {
      type: String,
      default: '',
    },
    primaryCallToAction: {
      type: Function,
      default: undefined,
    },
    secondaryCallToActionText: {
      type: String,
      default: '',
    },
    secondaryCallToAction: {
      type: Function,
      default: undefined,
    },
    testIdSuffix: {
      type: String,
      default: '',
    },
    primaryCallToActionLoading: {
      type: Boolean,
      default: false,
    },
    secondaryCallToActionDisabled: {
      type: Boolean,
      default: false,
    },
    secondaryButtonType: {
      type: String,
      default: THEME_BUTTON_TYPES.SECONDARY,
    },
    primaryButtonType: {
      type: String,
      default: THEME_BUTTON_TYPES.PRIMARY,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return {
      fetchButtonSpecs,
    };
  },
  computed: {
    isModalVisible() {
      return this.image
        || this.icon
        || this.title
        || this.description
        || this.extraInfo
        || this.secondaryCallToActionText
        || this.primaryCallToActionText;
    },
    testIdSuffixComposed() {
      return !isEmpty(this.testIdSuffix)
        ? `-${this.testIdSuffix}`
        : '';
    },
    buttonsCardinal() {
      return size(omitBy([this.primaryCallToAction, this.secondaryCallToAction], isNil));
    },
    computedActionClasses() {
      const alignClass = this.buttonsCardinal === 1 ? 'center' : 'end';
      return `d-flex align-items-center justify-content-${alignClass}`;
    },
  },
  created() {
    this.ALERT_TYPES = ALERT_TYPES;
  },
  methods: {
    concat,
  },
};
</script>
<template>
  <ui-modal
    v-if="isModalVisible"
    v-bind="$attrs"
    :open="isOpen"
    :size="modalSize"
    :header="(title && !image) ? title : null"
    :data-test-id="`modal-feedback${testIdSuffixComposed}`"
    :class="image ? 'header-hidden p-0' : ''"
    class="FeedbackModalComponent"
    @change="value => $emit('input', value)"
    @close="$emit('close-modal')"
  >
    <div slot="body">
      <figure
        v-if="image"
        class="FeedbackModalComponent__image-wrapper position-relative w-100 "
      >
        <img
          class="FeedbackModalComponent__image position-absolute w-100 h-100 emobg-border-radius-large"
          :class="{
            'p-4': imageWithPadding,
          }"
          alt="feedack-image"
          :src="image"
          data-test-id="modal-feedback-image"
        >
      </figure>
      <div :class="[{'mt-4 px-3 pb-4': image}]">
        <ui-icon
          v-if="icon"
          :icon="icon"
          :size="ICONS_SIZES.xLarge"
          :color="iconColor"
          class="d-inline-block mb-4"
          data-test-id="modal-feedback-icon"
        />
        <h4
          v-if="title && image"
          :class="type && `emobg-color-${type}`"
          class="emobg-font-large emobg-font-weight-bold text-center"
          data-test-id="modal-feedback-title"
        >
          {{ title }}
        </h4>
        <p
          v-if="description"
          :class="{ 'text-center': title && image, 'px-3': image }"
          class="mt-2 emobg-font-default"
          data-test-id="modal-feedback-description"
          v-html="description"
        />
        <p
          v-if="extraInfo"
          class="emobg-font-default text-center mt-2"
          data-test-id="modal-feedback-extra-info"
          v-html="extraInfo"
        />

        <AlertComponent
          v-if="errorMessage"
          :type="ALERT_TYPES.danger"
          class="w-100"
        >
          <p v-html="errorMessage" />
        </AlertComponent>
      </div>
    </div>
    <div
      v-if="secondaryCallToActionText || primaryCallToActionText"
      slot="footer"
      :class="computedActionClasses"
      data-test-id="feedback-modal--footer"
    >
      <ui-button
        v-if="secondaryCallToAction"
        v-bind="fetchButtonSpecs({ buttonType: secondaryButtonType })"
        :disabled="secondaryCallToActionDisabled"
        class="mr-2"
        data-test-id="modal-feedback-button1"
        @clickbutton="secondaryCallToAction"
      >
        {{ secondaryCallToActionText }}
      </ui-button>
      <ui-button
        v-if="primaryCallToAction"
        v-bind="fetchButtonSpecs({ buttonType: primaryButtonType })"
        :loading="primaryCallToActionLoading"
        data-test-id="modal-feedback-button2"
        @clickbutton="primaryCallToAction"
      >
        {{ primaryCallToActionText }}
      </ui-button>
    </div>
  </ui-modal>
</template>
<style scoped>
.header-hidden :deep(.Ui-Modal__header) {
  display: none;
}

.p-0 :deep(.Ui-Modal__body) {
  padding: 0;
}
</style>
