var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ResetPassword d-flex flex-row h-100 emobg-background-color-white"},[_c('Logo',{staticClass:"ResetPassword__logo--primary position-absolute d-lg-block d-none",attrs:{"variant":_vm.logoType}}),_c('div',{staticClass:"ResetPassword__left-side d-lg-flex d-none ecod-gradient-background",attrs:{"data-test-id":"left_panel-container"}},[_c('div',{staticClass:"w-100",style:({
        backgroundImage: _vm.leftImageUrl,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom left',
      })})]),_c('div',{staticClass:"ResetPassword__right-side position-relative d-flex flex-column align-items-start overflow-scroll"},[_c('NotificationListManager',{staticClass:"fixed-top"}),_c('div',{staticClass:"text-center full-width"},[_c('Logo',{staticClass:"ResetPassword__logo--responsive position-relative d-lg-none d-block py-4"})],1),_c('div',{staticClass:"full-width h-100 d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"RightSide__wrapper d-flex flex-wrap justify-content-center p-lg-0 px-4 w-100"},[(!_vm.isForced)?_c('span',{staticClass:"w-100"},[_c('h4',{staticClass:"emobg-font-x-large emobg-font-weight-bold mb-3 w-100",domProps:{"innerHTML":_vm._s(_vm.$t('refactor.request_reset_password.title'))}}),_c('div',{staticClass:"mb-5 w-100",domProps:{"innerHTML":_vm._s(_vm.$t('refactor.request_reset_password.text'))}})]):_c('span',[_c('h4',{staticClass:"emobg-font-x-large emobg-font-weight-bold mb-3 w-100",domProps:{"innerHTML":_vm._s(_vm.$t('refactor.force_request_reset_password.title'))}}),_c('div',{staticClass:"mb-5 w-100",domProps:{"innerHTML":_vm._s(_vm.$t('refactor.force_request_reset_password.text', { operator: _vm.appName }))}})]),_c('ui-form',{staticClass:"w-100",attrs:{"validate":""}},[_c('ui-validate',{on:{"status":({ detail }) => _vm.formStatus = detail}},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
                isEmail: {
                  message: _vm.$t('email_input.invalid_email'),
                },
                isRequired: {
                  message: _vm.$t('refactor.company_signup.errors.mandatory'),
                  whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
                },
              }),expression:"{\n                isEmail: {\n                  message: $t('email_input.invalid_email'),\n                },\n                isRequired: {\n                  message: $t('refactor.company_signup.errors.mandatory'),\n                  whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n                },\n              }",modifiers:{"blurinput":true}}],staticClass:"w-100 d-block mb-5",attrs:{"label":_vm.$t('refactor.request_reset_password.input_label'),"placeholder":_vm.$t('refactor.request_reset_password.input_placeholder'),"type":_vm.INPUT_TYPES.email,"value":_vm.email,"data-test-id":"email-input","name":"email"},on:{"blurinput":({ detail }) => _vm.setEmail(detail),"focusinput":_vm.resetInputValidation}}),_c('ui-button',_vm._b({staticClass:"pb-lg-0 pb-6 d-block",attrs:{"disabled":!_vm.isFormValid,"loading":_vm.isLoading,"size":_vm.SIZES.large,"data-test-id":"request_reset_password-button"},on:{"clickbutton":function($event){return _vm.onClickConfirmEmail()}}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('refactor.request_reset_password.button_text'))+" ")])],1)],1)],1)])],1),_c('FeedbackModalComponent',_vm._b({attrs:{"is-open":_vm.modal.isOpen}},'FeedbackModalComponent',_vm.modal,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }