import routes from './routes-names';

const AccountSettings = () => import(/* webpackChunkName: "settings" */'@Account/Settings/AccountSettings');

const basePath = '/account-settings';

export const AccountSettingsRoute = {
  path: basePath,
  name: routes.accountSettings,
  redirect: {
    name: routes.personalInformation,
  },
};

export const PersonalInformationRoute = {
  path: `${basePath}/personal-information`,
  name: routes.personalInformation,
  component: AccountSettings,
  meta: {
    requireAuth: true,
    requireLoader: false,
  },
};

export const DrivingLicenseRoute = {
  path: `${basePath}/driving-license`,
  name: routes.drivingLicense,
  component: AccountSettings,
  props: true,
  meta: {
    requireAuth: true,
  },
};

export const BadgeRoute = {
  path: `${basePath}/badge`,
  name: routes.badge,
  component: AccountSettings,
  meta: {
    requireAuth: true,
  },
};

export const PaymentMethodsRoute = {
  path: `${basePath}/payment-methods`,
  name: routes.paymentMethods,
  component: AccountSettings,
  meta: {
    requireAuth: true,
  },
};

export const NotificationsRoute = {
  path: `${basePath}/notifications`,
  name: routes.notifications,
  component: AccountSettings,
  meta: {
    requireAuth: true,
  },
};

export default [
  AccountSettingsRoute,
  PersonalInformationRoute,
  DrivingLicenseRoute,
  BadgeRoute,
  PaymentMethodsRoute,
  NotificationsRoute,
];

