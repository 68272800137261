export const ACTIONS = {
  add: 'add',
  select: 'select',
  list: 'list',
  payment: 'payment',
};

export const RESPONSE_STATUS = {
  redirectShopper: 'RedirectShopper',
  challengeShopper: 'ChallengeShopper',
  identifyShopper: 'IdentifyShopper',
  authorised: 'authorised',
  challenge: 'challenge',
  identify: 'identify',
  refused: 'refused',
  pending: 'pending',

  error: 'error',
  cancelled: 'cancelled',
  presentToShopper: 'presentToShopper',
  received: 'received',

  redirectAuthorised: 'redirectAuthorised',
};

export const PAYMENT_CHANNELS = {
  web: 'web',
};

export const PAYMENT_TYPES = {
  card: 'card',
  iban: 'iban',
  sepa: 'sepa',
  bankTransfer: 'bank_transfer',
  payment: 'payment',
  compensation: 'compensation',
  cash: 'cash',
  promotion: 'promotion',
};

export const STAGE = {
  formValid: 'FORM_VALID',
  refused: 'REFUSED',
  editable: 'EDITABLE',
  submitting: 'SUBMITTING',
  submitted: 'SUBMITTED',
  redirect: 'REDIRECT',
  threeDS2Challenge: 'THREEDS2CHALLENGE',
  threeDS2Fingerprint: 'THREEDS2FINGERPRINT',
};

export const PROVIDER_USED = 'providerUsed';

export const ACTION_USED = 'actionUsed';

export const STORAGE_3DS_DATA = 'three3DS-data';

export const EDIT_BOOKING_QUERY = 'edit_booking';

export const MATCHING_NAME = 'matchingName';

export const PAYMENT_HUB_CURRENT_URL = 'paymentHubCurrentUrl';

export const HUB_REDIRECT_FLOW = 'hubRedirectFlow';

export const HUB_REDIRECT_FLOW_STATUS = {
  fromRedirect: 'fromRedirect',
  fromRedirectQueryString: 'status=fromRedirect',
  toRedirect: 'toRedirect',
  toRedirectQueryString: 'status=toRedirect',
  redirectStep0: 'redirectStep-0',
  redirectStep0QueryString: 'status=redirectStep-0',
  redirectReSent: 'redirectReSent',
};

export const HIERARCHY = 'hierarchy';

export const PAYMENT_REDIRECTED = 'paymentRedirected';

export const FORCED_MATCHING_NAME = {
  booking: 'booking',
};

export const CUSTOMER_TYPE = {
  business: 'business',
  user: 'user',
  company: 'company',
  companyAdmin: 'company-admin',
};

export const ORIGIN = {
  challenge: 'challenge',
  identify: 'identify',
  redirect: 'redirect',
  forcedError: 'forcedError',
};

export const PAYMENT_MODAL_ACTIONS = {
  add: 'add',
  refuse: 'refuse',
  close: 'close',
  open: 'open',
};

export const CHALLENGE_ENDPOINT_VERSION = {
  payment: 'v4',
  add: 'v3',
};

export const SOURCE_STORE = {
  hub: 'hub',
  listener: 'listener',
};

export const BOOKING_FLOW = {
  inProcess: 'BOOKING_IN_PROCESS',
  stage: {
    authorised: 'authorised',
    refused: 'refused',
    init: 'init',
    error: 'error',
  },
};

export const BOOKING_PREVIOUS_PAGE = 'bookingPreviousPage';

export const DEFAULT_PAYMENT_PROVIDER = 'Adyen/3.23.0';

export const DEFAULT_TRACKING_PROVIDER = './trackingProviders/segment/segmentTracking.js';

export const ADYEN_71_API_VERSION = 'v6';

export const ADYEN_VERSION_THRESHOLD = 4;

export const SOURCE_PAGES = {
  insurance: 'insurancePSD2',
  editBooking: 'editBookingPSD2',
  booking: 'bookingPSD2',
  invoices: 'invoicePSD2',
};

export const NOTIFICATION_MESSAGE_TYPES = {
  error: 'error',
  success: 'success',
  warning: 'warning',
};

export const EDIT_BOOKING_VARIANT = {
  editInsurance: 'editInsurance',
  editTime: 'editTime',
  splitter: '/',
};

export const HOLDER_NAME_REDIRECT_MATCH = 'REDIRECT';

export const BOOKING_RESOURCE_TYPE = 'booking';
