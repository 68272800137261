import {
  COUNTRIES_ISO_CODES,
  LOG_TYPE,
  logger,
} from '@emobg/web-utils';
import { VALIDATION_EVENT_MODIFIERS } from '@emobg/vue-base';
import toUpper from 'lodash/toUpper';
import each from 'lodash/each';
import get from 'lodash/get';

function getHintKey({ defaultHint, hint }) {
  if (!this.$i18n) {
    logger.message('Bind the function with a Vue Component instance', LOG_TYPE.warning);
    return '';
  }

  const hasTranslation = this.$i18n.keyExists(hint, 'strict');

  return hasTranslation ? hint : defaultHint;
}

export function getAddressHintKey(countryCode) {
  const defaultHint = `refactor.user_signup.step_4.hint.${COUNTRIES_ISO_CODES.spain}.address`;
  const hint = `refactor.user_signup.step_4.hint.${countryCode}.address`;

  return getHintKey.call(this, { defaultHint, hint });
}

export function getAddressDetailsHintKey(countryCode) {
  const defaultHint = `refactor.user_signup.step_4.hint.${COUNTRIES_ISO_CODES.spain}.additional_address`;
  const hint = `refactor.user_signup.step_4.hint.${countryCode}.additional_address`;

  return getHintKey.call(this, { defaultHint, hint });
}

export function shouldRenderIdealPostCode(countryCode) {
  if (!this.$featureFlag) {
    throw new Error('Bind the function with a Vue Component instance');
  }

  const isUK = toUpper(countryCode) === COUNTRIES_ISO_CODES.great_britain;
  return isUK && this.$featureFlag.flags.ukIdealPostcodes;
}

/**
 * Checks wether a user both resides in Italy and is using an italian operator or wether a company both is
 * registered in italy and has an italian address.
 * @param {string} countryCode Code of the country for the operator
 * @param {string} addressCountryCode Code of the country for the user address
 * @returns {boolean}
 */
export const isItalianUserOrCompany = (
  countryCode,
  addressCountryCode,
) => countryCode === COUNTRIES_ISO_CODES.italy && addressCountryCode === COUNTRIES_ISO_CODES.italy;

/**
 * Validates an array of $refs
 */
export const validateRefs = (referenceNames, $refs, data) => {
  each(referenceNames, referenceName => {
    const refValue = get(data, referenceName);
    const refOject = get($refs, referenceName);
    if (refValue && refOject) {
      refOject.dispatchEvent(new Event(VALIDATION_EVENT_MODIFIERS.validate));
    }
  });
};
