import { external } from '@emobg/web-api-client';
import {
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';

export const scopes = {
  rideshares: 'rideshares',
};

export const NAMESPACE = 'CARPOOLING';

export const ACTIONS = {
  getRideshares: 'GET_RIDESHARES',
};

export const Carpooling = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, (
  {
    mutations: {
      setError(state, error) {
        const errorMessage = get(error, 'message');
        state.rideshares.error = errorMessage;
        throw new Error(errorMessage);
      },
    },
    getters: {
      rideshares(state) {
        return get(state, 'rideshares.data');
      },
      ridesharesLocations(_, getters) {
        return map(uniqBy(getters.rideshares, 'location.name'), 'location.name');
      },
      sortRidesharesBy(_, getters) {
        return (field, transformer, rideshares = getters.rideshares) => sortBy(rideshares, rideshare => (isNil(transformer)
          ? get(rideshare, field)
          : transformer(get(rideshare, field))));
      },
      filterRidesharesBy: (_, getters) => filters => reduce(filters, (results, filterFn) => filter(results, filterFn), getters.rideshares),

      refinedRideshares: (_, getters) => (filterByValues = [], sortByValues = {}) => {
        const ridesharesFiltered = getters.filterRidesharesBy(filterByValues);
        const { field, transformer } = sortByValues;
        return getters.sortRidesharesBy(field, transformer, ridesharesFiltered);
      },
      actionHasFailed(state) {
        return !isNil(get(state, 'rideshares.error'));
      },
    },
    actions: {
      async getRideshares({ commit }) {
        await runAsyncFlow(commit, {
          request: external.booking.getRideshares,
          scope: scopes.rideshares,
        });
      },
      async joinRideshare({ commit }, rideshareUuid) {
        await runAsyncFlow(commit, {
          request: external.booking.putJoinRideshare,
          params: [rideshareUuid],
        });
      },
      async cancelRideshare({ commit }, rideshareUuid) {
        await runAsyncFlow(commit, {
          request: external.booking.putCancelRideshare,
          params: [rideshareUuid],
        });
      },
      async leaveRideshare({ commit }, rideshareUuid) {
        await runAsyncFlow(commit, {
          request: external.booking.putLeaveRideshare,
          params: [rideshareUuid],
        });
      },
      async cancelBookingRideshareTrips({ commit }, bookingUuid) {
        await runAsyncFlow(commit, {
          request: external.booking.putCancelCarpoolingTrips,
          params: [bookingUuid],
        });
      },
      async createCarpooling({ commit }, payload) {
        await runAsyncFlow(commit, {
          request: external.booking.postCarsharing,
          params: [payload],
        });
      },
      async clearError({ commit }) {
        commit('setError', null);
      },
    },
  }
)));
