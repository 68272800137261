import { createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import get from 'lodash/get';

export const ACTIONS = {
  confirmPhishingBadge: 'confirmPhishingBadge',
  rejectPhishingBadge: 'rejectPhishingBadge',
};

export const MUTATIONS = {
  setError: 'setError',
};

export const GETTERS = {
  phishingBadgeData: 'phishingBadgeData',
};

export const SCOPES = {
  phishingBadgeResponse: 'phishingBadgeResponse',
};

export const NAMESPACE = 'PhishingBadge';

export const PhishingBadgeStore = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  mutations: {
    [MUTATIONS.setError](state, error) {
      state.phishingBadgeResponse.error = get(error, 'value');
      throw new Error(get(error, 'value.message'));
    },
  },
  getters: {
    [GETTERS.phishingBadgeData]: state => state.phishingBadgeResponse,
  },
  actions: {
    async [ACTIONS.confirmPhishingBadge]({ commit }, { token }) {
      await runAsyncFlow(commit, {
        request: external.badges.postValidatePhishingBadge,
        params: [token],
        scope: SCOPES.phishingBadgeResponse,
      });
    },
    async [ACTIONS.rejectPhishingBadge]({ commit }, { token }) {
      await runAsyncFlow(commit, {
        request: external.badges.postRefusePhishingBadge,
        params: [token],
        scope: SCOPES.phishingBadgeResponse,
      });
    },
  },
})));
