import moment from 'moment';

export default class Rules {
  constructor(config) {
    this.config = config;
  }

  getMinDuration() {
    return this.config.getInterventionDefaultMinBookingDuration();
  }

  getMaxDuration() {
    return this.config.getInterventionDefaultMaxBookingDuration();
  }

  getMinimumEnd(start) {
    return moment(start).add(this.getMinDuration(), 'm').subtract(1, 'second');
  }

  getEnd(start) {
    return moment(start).add(this.getMinDuration(), 'm');
  }

  getMaximumEnd(start) {
    return moment(start).add(this.getMaxDuration(), 'm');
  }
}
