import { authService } from '@emobg/access-utils';

/**
 * Parse the #hash in URL to get the Auth0 data (accessToken and idToken)
 * using a parseHash method that Auth0 provides. This method is used after
 * the redirection from the Log in to WebApp.
 * @returns {Promise<{accessToken, idToken}> | Promise<undefined>}
 */
export const parseHash = () => new Promise((resolve, reject) => {
  authService.instance.auth0.parseHash((error, authResult) => {
    if (error) {
      reject(error);
      return;
    }

    const {
      accessToken,
      idToken,
    } = authResult || {};

    if (accessToken && idToken) {
      resolve(authResult);
      return;
    }

    resolve();
  });
});
