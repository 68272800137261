import { authService } from '@emobg/access-utils';
import { isAuthenticationNeeded } from './helpers/isAuthenticationNeeded';
import { fakeAuthResult, getImpersonation } from './helpers/impersonation';
import { getRedirection } from './helpers/redirection';
import { parseHash } from './helpers/parseHash';

/**
 * Manage the authentication of the user redirecting to the Login page
 * or getting the authentication data from the URL when it's needed
 * @returns {Promise<{ isRedirecting: boolean }>}
 */
export const handleAuthentication = () => new Promise(async resolve => { // eslint-disable-line no-async-promise-executor
  const impersonation = getImpersonation(window.localStorage);
  if (impersonation.isImpersonate) {
    authService.instance.setAuthResult(fakeAuthResult({
      expiresAt: impersonation.expiresAt,
      idpUuid: impersonation.idpUuid,
      accessToken: impersonation.accessToken,
    }));
  }

  if (!authService.instance.isAuthenticated()) {
    const authResult = await parseHash();
    if (authResult) {
      await authService.instance.setSession(authResult);
      const redirection = getRedirection();
      redirection.redirect();

      if (!redirection.isLocalRedirection) {
        resolve({ isRedirecting: true });
        return;
      }
    }
  }

  if (isAuthenticationNeeded()) {
    if (!authService.instance.isAuthenticated()) {
      authService.instance.login();
      resolve({ isRedirecting: true });
      return;
    }

    if (!authService.instance.accessToken) {
      await authService.instance.renewTokens();
    }
  }

  resolve({ isRedirecting: false });
});
