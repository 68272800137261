import getInitialState from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  actions,
  getters,
};

export const nameSpace = 'Profile';

export { ACTIONS } from './actions';

export { GETTERS } from './getters';
