export const ASSETS_CATEGORY = {
  ILLUSTRATIONS: 'illustrations',
  PICTURES: 'pictures',
  MARKERS: 'markers',
  LOGO: 'logo',
};

export const LOGOS_KEYNAMES = {
  PRIMARY_LOGO: 'primary-logo',
  CONTRAST_LOGO: 'contrast-logo',
  FAVICON: 'favicon.ico',
};

export const ILLUSTRATIONS_KEYNAMES = {
  BOOKING_CONFIRMED: 'booking-confirmed',
  BOOKING_CANCELLED: 'booking-cancelled',
  CARD: 'card',
  DOCUMENT: 'document',
  DOCUMENT_BG: 'document_bg',
  BLOCKED: 'blocked',
  OK: 'ok',
  ERROR: 'error',
  LOCATION: 'location',
  THANKS: 'thanks',
  PROTECTION: 'protection',
  EDIT_TIME_SUCCESS: 'edit-time-success',
  EDIT_TIME_ERROR: 'edit-time-error',
  RESET_PASSWORD_EMAIL: 'reset-password-email',
  NO_ACTIVE_BOOKING: 'no-active-booking',
  CARPOOLING_TRIPS: 'carpooling-trips',
  UBEEQO_TO_ONDEMAND: 'ubeeqo-to-ondemand',
  DOWNLOAD_APP: 'download-app',
};

export const MARKERS_NAMES = {
  BRAND: 'brand',
  BRAND_HOVER: 'brandHover',
  CITY_MARKER: 'cityMarker',
  BUSINESS_BRAND: 'businessBrand',
  CLUSTER: 'cluster',
  USER_MARKER: 'userMarker',
};

export const PICTURES_KEYNAMES = {
  SIGNUP: {
    b2b: 'signup-b2b.png',
    b2c: 'signup-b2c.png',
  },
  PROMOTIONS_BANNER: 'promotions-banner.png',
  INVITE_FRIEND: {
    car: 'invite-friend-car.png',
    trail: 'invite-friend-trail.png',
  },
  KEYCARD: 'keycard-side-image.png',
  MFA_BACKGROUND: '2fa-bg-image.png',
};
