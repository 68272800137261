import moment from 'moment';
import Rules from './SharedInterventionRules';
export default class InterventionRules {
  constructor(config) {
    this.config = config;

    this.rules = new Rules(config);

    // When  Intervention Rules are used to edit a booking
    // we have to check if booking is started or not
    if (config.start && this.getNextStart().isSameOrAfter(config.start)) {
      this.minimumStart = moment(config.start).subtract(1, 'second');
    } else {
      this.minimumStart = moment(this.getNextStart()).subtract(1, 'second');
    }

    this.start = config.start || this.getNextStart();

    this.maximumStart = false;

    this.nextStart = null;

    this.stepping = this.config.getIntervalTimeUnit();

    const defaultEnd = (() => {
      const defaultDuration = this.config.getInterventionDefaultDuration();
      const minimumDuration = this.config.getInterventionDefaultMinBookingDuration();

      // First configuration of datetimepickers has to be the default
      // configuration, but it can't be less than minimum duration
      if (defaultDuration >= minimumDuration) {
        return moment(this.start).add(defaultDuration, 'm');
      }

      return moment(this.start).add(minimumDuration, 'm');
    })();

    if (config.end) {
      if (moment(config.end).isBetween(this.getRules().getMinimumEnd(this.start),
        this.getRules().getMaximumEnd(this.start))) {
        this.end = config.end;
      } else {
        this.end = defaultEnd;
      }
    } else {
      this.end = defaultEnd;
    }

    this.maximumEnd = this.getRules().getMaximumEnd(this.start);
    this.minimumEnd = this.getRules().getMinimumEnd(this.start);
  }

  getNextStart() {
    if (this.nextStart) {
      return this.nextStart;
    }

    const now = moment();
    const gap = this.getStepping();
    const offset = gap - (now.minutes() % gap);
    now.add(offset, 'm').seconds(0).milliseconds(0);
    this.nextStart = now;

    return this.getNextStart();
  }

  getGap() {
    return this.config.getInterventionBookingGap();
  }

  getMinimumStart() {
    return this.minimumStart;
  }

  setMinimumStart(val) {
    this.minimumStart = val;
  }

  getStart() {
    return this.start;
  }

  setStart(val) {
    this.start = val;
  }

  getMaximumStart() {
    return this.maximumStart;
  }

  setMaximumStart(val) {
    this.maximumStart = val;
  }

  getMinimumEnd() {
    return this.minimumEnd;
  }

  setMinimumEnd(val) {
    this.minimumEnd = val;
  }

  getEnd() {
    return this.end;
  }

  setEnd(val) {
    this.end = val;
  }

  getMaximumEnd() {
    return this.maximumEnd;
  }

  setMaximumEnd(val) {
    this.maximumEnd = val;
  }

  getRules() {
    return this.rules;
  }

  getStepping() {
    return this.config.getIntervalTimeUnit();
  }
}
