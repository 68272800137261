import { useAuth } from '@/composable/Api/Auth/auth';
import SIGN_UP_ROUTES from './routes-names';

const SignUpView = () => import(/* webpackChunkName: "signup" */'../SignUp');

// import SignUpCompany
const SignUpCompany = () => import(/* webpackChunkName: "signup" */'../Company/Company');
const SignUpCompanyStep1 = () => import(/* webpackChunkName: "signup" */'../Company/Step1/Step1');
const SignUpCompanyStep2 = () => import(/* webpackChunkName: "signup" */'../Company/Step2/Step2');
const SignUpCompanyStep3 = () => import(/* webpackChunkName: "signup" */'../Company/Step3/Step3');

const SignUpUser = () => import(/* webpackChunkName: "signup" */'../User/UserView');
const SignUpUserStep1 = () => import(/* webpackChunkName: "signup" */'../User/Step1/Step1View');
const SignUpUserStep2 = () => import(/* webpackChunkName: "signup" */'../User/Step2/Step2View');
const SignUpUserStep3 = () => import(/* webpackChunkName: "signup" */'../User/Step3/Step3View');

// import B2B (Mail doesn't exist)
const SignUpB2B = () => import(/* webpackChunkName: "signup" */ '../B2B/B2BView');
const InvitationView = () => import(/* webpackChunkName: "signup" */ '../B2B/Invitation/InvitationView');

const redirections = [
  {
    // Redirect from the previous Sign up to the current one
    path: '/sign-up',
    redirect: '/signup/user',
  },
  {
    path: '/signup-professional-company',
    redirect: '/signup/company',
  },
  {
    path: '/:lang/auth/business/signup',
    redirect: '/signup/company',
  },
  {
    path: '/:invitationToken/:invitationUUID/confirm-invitation-employee',
    redirect: to => {
      const { invitationToken, invitationUUID } = to.params;
      return { name: SIGN_UP_ROUTES.businessInvitationStep1, params: { invitationToken, invitationUUID } };
    },
    meta: {
      requireAuth: false,
      responsive: true,
    },
  },
  {
    path: '/preregister/:employeeData/signup',
    redirect: to => ({ name: SIGN_UP_ROUTES.businessPreregister, params: { employeeData: to.params.employeeData } }),
    meta: {
      requireAuth: false,
      responsive: true,
    },
  },
];

export default [
  ...redirections,
  {
    path: '/:invitationCode/confirm-invitation',
    name: 'ConfirmInvitationCode',
    redirect: to => ({ name: SIGN_UP_ROUTES.userStep1, params: { promo: to.params.invitationCode } }),
    meta: {
      requireAuth: false,
      responsive: true,
    },
  },
  {
    path: '/signup',
    name: 'SignUpView',
    component: SignUpView,
    redirect: '/signup/user',
    meta: {

    },
    children: [
      {
        path: 'spain',
        name: 'SignUpSpain',
        redirect: () => '/signup',
      },
      {
        path: 'company',
        name: 'SignUp_Company',
        component: SignUpCompany,
        children: [
          {
            path: '/',
            redirect: 'step1',
          },
          {
            path: 'step1',
            name: SIGN_UP_ROUTES.step1,
            component: SignUpCompanyStep1,
          },
          {
            path: 'step2',
            name: SIGN_UP_ROUTES.step2,
            component: SignUpCompanyStep2,
          },
          {
            path: 'step3',
            name: SIGN_UP_ROUTES.step3,
            component: SignUpCompanyStep3,
          },
          {
            path: '*',
            redirect: 'step1',
          },
        ],
      },
      {
        path: 'user',
        name: SIGN_UP_ROUTES.user,
        component: SignUpUser,
        children: [
          // TODO: fix the routes warning. It's repeated willfully
          // To achieve same bullet behaviour.
          {
            path: ':invitationToken/:invitationUUID/step1',
            name: SIGN_UP_ROUTES.userInvitationStep1,
            component: SignUpUserStep1,
          },
          {
            path: ':invitationToken/:invitationUUID/step2',
            name: SIGN_UP_ROUTES.userInvitationStep2,
            component: SignUpUserStep2,
          },
          {
            path: ':invitationToken/:invitationUUID/step3',
            name: SIGN_UP_ROUTES.userInvitationStep3,
            component: SignUpUserStep3,
          },
          {
            path: ':employeeData/step1',
            name: SIGN_UP_ROUTES.preregisterInvitationStep1,
            component: SignUpUserStep1,
          },
          {
            path: ':employeeData/step2',
            name: SIGN_UP_ROUTES.preregisterInvitationStep2,
            component: SignUpUserStep2,
          },
          {
            path: ':employeeData/step3',
            name: SIGN_UP_ROUTES.preregisterInvitationStep3,
            component: SignUpUserStep3,
          },
          {
            path: 'step1',
            name: SIGN_UP_ROUTES.userStep1,
            component: SignUpUserStep1,
          },
          {
            path: 'step2',
            name: SIGN_UP_ROUTES.userStep2,
            component: SignUpUserStep2,
          },
          {
            path: 'step3',
            name: SIGN_UP_ROUTES.userStep3,
            component: SignUpUserStep3,
          },
          {
            path: '*',
            redirect: 'step1',
          },
          {
            path: '/',
            redirect: 'step1',
          },
        ],
      },
      {
        path: 'business',
        name: SIGN_UP_ROUTES.business,
        component: SignUpB2B,
        children: [
          {
            path: ':invitationToken/:invitationUUID/step1',
            name: SIGN_UP_ROUTES.businessInvitationStep1,
            component: InvitationView,
          },
          {
            // Data can be a `employeeUuid` or an `email`
            // `email` is encrypted in the middleware
            path: ':employeeData/step1',
            name: SIGN_UP_ROUTES.businessPreregister,
            component: InvitationView,
          },
        ],
      },
      {
        path: '*',
        redirect: 'company',
      },
    ],
  },
  {
    path: '/login/spain',
    name: 'LoginSpain',
    redirect: () => {
      const { logout } = useAuth();
      logout();
    },
  },
];
