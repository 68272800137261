import Cookie from 'js-cookie';
import { STORAGE_NAMES } from '@emobg/web-utils';
import {
  isLocationSupported,
  isReplaceStateSupported,
  locationRedirect,
  replaceState,
} from '@/utils/browserNavigation';

/**
 * Redirect the browser to an specific URL. It can be done from 2 ways,
 * depending of the browser compatibility:
 * - If the "returnUrl" cookie is in place, it redirects with a window.location.href to
 * this URL, without checking if it's valid or not
 * - Use window.history.replaceState when want to remove the query string and hash
 * but we want to redirect to the same URL. It doesn't refresh the page and it's faster
 * @throws Error - Returns an error when window.location is not needed and not supported
 * @returns {{redirect: redirect, isLocalRedirection: boolean}}
 */
export const getRedirection = () => {
  const DEFAULT_URL = '/';
  const returnUrl = Cookie.get(STORAGE_NAMES.returnUrl);

  const hasReplaceStateSupport = isReplaceStateSupported();
  const hasLocationSupport = isLocationSupported();

  // If returnUrl is defined or browser doesn't support the history.replaceState
  // we have to force a redirection to the returnUrl or the default URL
  const redirectUrl = (returnUrl || !hasReplaceStateSupport)
    ? (returnUrl || DEFAULT_URL)
    : null;

  if ((redirectUrl && !hasLocationSupport)) {
    throw new Error('Error trying to resolve the redirection');
  }

  if (redirectUrl) {
    // Redirect to the URL and additionally, the #hash is removed
    return {
      isLocalRedirection: false,
      redirect: () => {
        locationRedirect(redirectUrl);
      },
    };
  }

  // Remove the #hash without refreshing the page
  return {
    isLocalRedirection: true,
    redirect: () => {
      replaceState();
    },
  };
};

