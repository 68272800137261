// @see https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-component?tab=v66-payment-response_2#3ds2-component for more info about available iframe sizes
/*
  01  250*400
  02  390*400
  03  500*600
  04  600*400
  05  100%*100%
*/

export const providerName = 'adyen';

export const DEFAULT_IFRAME_SIZE = '04';

export const IFRAME_ALL_WIDTH = '05';

export const DEFAULT_PLACEHOLDERS = {
  encryptedCardNumber: '4111 1111 1111 1111',
  encryptedExpiryDate: '03/30',
  encryptedSecurityCode: '737',
};

export const DEFAULT_LOCALE = 'en-GB';

export const MASTERCARD_TYPE = {
  short: 'mc',
  long: 'master_card',
};

export const PAYMENT_METHODS_CONFIG = {
  groups: [
    {
      name: 'Credit Card',
      types: ['mc', 'visa', 'amex', 'maestro', 'cup'],
    },
  ],
  oneClickPaymentMethods: [],
  paymentMethods: [
    {
      brands: ['mc', 'visa', 'amex', 'maestro'],
      details: [
        {
          key: 'number',
          type: 'text',
        },
        {
          key: 'expiryMonth',
          type: 'text',
        },
        {
          key: 'expiryYear',
          type: 'text',
        },
        {
          key: 'cvc',
          type: 'text',
        },
        {
          key: 'holderName',
          optional: true,
          type: 'text',
        },
      ],
      name: 'Credit Card',
      type: 'scheme',
    },
  ],
  storedPaymentMethods: [],
};

export const ADYEN_ENVIRONMENT_CONFIG = {
  test: 'test',
  live: 'live',
};
