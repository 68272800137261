import get from 'lodash/get';

import { PHISHING_ROUTES_NAMES } from './routes-names';
import { PHISHING_BADGE_ACTIONS } from '../constants';

const PhishingBadgeComponent = () => import(/* webpackChunkName: "phishing-badge" */'../PhishingBadge');

export const PhishingBadgeMailEntryConfirm = {
  path: `/${PHISHING_ROUTES_NAMES.phishingBadge}/confirm`,
  name: PHISHING_ROUTES_NAMES.confirmPhishingBadge,
  component: PhishingBadgeComponent,
  meta: {
    requireAuth: false,
    requireLoader: true,
    responsive: true,
  },
  props: route => ({
    token: get(route, 'query.token'),
    csOperatorUuid: get(route, 'query.cs_operator_uuid'),
    action: PHISHING_BADGE_ACTIONS.confirm,
  }),
};

export const PhishingBadgeMailEntryReject = {
  path: `/${PHISHING_ROUTES_NAMES.phishingBadge}/reject`,
  name: PHISHING_ROUTES_NAMES.rejectPhishingBadge,
  component: PhishingBadgeComponent,
  meta: {
    requireAuth: false,
    requireLoader: true,
    responsive: true,
  },
  props: route => ({
    token: get(route, 'query.token'),
    csOperatorUuid: get(route, 'query.cs_operator_uuid'),
  }),
};

export default [
  PhishingBadgeMailEntryConfirm,
  PhishingBadgeMailEntryReject,
];
