export default class Location {
  constructor(response) {
    const data = response || {};

    this.uuid = data.uuid;
    this.name = data.name;
    this.address = data.address || '';
    this.display_address = data.displayAddress || '';
    this.city = data.city || '';
    this.gps_lat = data.gpsLat;
    this.gps_lng = data.gpsLng;
    this.neighborhood = data.neighborhood;
    this.type = data.parking;
    this.description = data.description;
    this.open_location = data.openLocation;
    this.images = data.images;

    this.exists = () => response;
  }

  getUUID() {
    return this.uuid;
  }

  getAddress() {
    return this.address;
  }

  getCity() {
    return this.city;
  }

  getLatitude() {
    return this.gps_lat;
  }

  getLongitude() {
    return this.gps_lng;
  }

  getType() {
    return this.type;
  }
}
