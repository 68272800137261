import noop from 'lodash/noop';
import { GRAYSCALE } from '@emobg/web-components';
import { genericError, genericUserBlock, successOk } from '@/utils/publicImages';
import GENERIC_MODAL_TYPES from '@Shared/components/FeedbackModal/constants/genericModalTypes';

export const defaultHeaderConfig = {
  backColor: GRAYSCALE.white,
  color: GRAYSCALE.ink,
  class: 'pl-4 emobg-font-weight-bold',
};

export function genericErrorArgs($t) {
  return {
    title: $t('modal.signup.error.title'),
    image: genericError,
    primaryCallToAction: noop,
    primaryCallToActionText: $t('buttons.got_it'),
    description: $t('notifications.whooops'),
    testIdSuffix: GENERIC_MODAL_TYPES.error,
  };
}

export function genericSuccessArgs($t) {
  return {
    title: '',
    image: successOk,
    primaryCallToAction: noop,
    primaryCallToActionText: $t('buttons.got_it'),
    description: '',
    testIdSuffix: GENERIC_MODAL_TYPES.success,
  };
}

export function genericUserBlockArgs($t) {
  return {
    title: $t('modal.signup.error.title'),
    image: genericUserBlock,
    primaryCallToAction: noop,
    primaryCallToActionText: $t('buttons.got_it'),
    description: $t('notifications.whooops'),
    testIdSuffix: GENERIC_MODAL_TYPES.userRoadBlock,
  };
}

export function genericConfirmArgs($t) {
  return {
    title: $t('modal.signup.error.title'),
    primaryCallToAction: noop,
    primaryCallToActionText: $t('buttons.got_it'),
    secondaryCallToActionText: $t('buttons.cancel'),
    secondaryCallToAction: noop,
    description: '',
    testIdSuffix: GENERIC_MODAL_TYPES.confirm,
  };
}

export function genericWarningConfirmArgs($t) {
  return {
    title: $t('modal.signup.error.title'),
    primaryCallToAction: noop,
    primaryCallToActionText: $t('buttons.got_it'),
    secondaryCallToActionText: $t('buttons.cancel'),
    secondaryCallToAction: noop,
    description: '',
    testIdSuffix: GENERIC_MODAL_TYPES.confirm,
  };
}
