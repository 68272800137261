import get from 'lodash/get';
import reject from 'lodash/reject';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import size from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';

import {
  LOG_TYPE,
  STORE_REQUEST_STATUS,
  initialState,
  logger,
  runAsyncFlow,
  setData,
  setError,
  setStatus,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

export const NAMESPACE = 'SetupNewKeyCard';

export const SCOPE = {
  linkCard: 'linkCard',
};

const STEPS = {
  selectKeyCardType: 'InitialStep',
  linkExistingKeyCard: 'LinkExisting',
  orderNewKeyCard: 'NewKeyCard',
};

const initialModuleState = {
  ...cloneDeep(initialState),
  formData: {
    cardType: null,
    deliveryAddress: {
      address: null,
      address_details: null,
    },
    userUUID: null,
  },
  data: {
    csOperatorHasPickUp: null,
    step: STEPS.selectKeyCardType,
    keyCardsTypes: [],
    formInitialized: false,
  },
  [SCOPE.linkCard]: cloneDeep(initialState),
};

export const GETTERS = {
  getCustomKeyCards: 'getCustomKeyCards',
  isFirstStepDisabled: 'keycardConfigureInitialStepNextButtonDisabled',
  isKeycardLoaded: 'isKeycardLoaded',
  isNextButtonDisabled: 'keycardConfigureNewKeycardNextButtonDisabled',
  selectedKeyCard: 'selectedKeyCard',
};

const storeGetters = {
  [GETTERS.isFirstStepDisabled](state) {
    return isNull(get(state, 'formData.cardType'));
  },
  [GETTERS.selectedKeyCard](state) {
    const keyCards = get(state, 'data.keyCardsTypes');
    const cardType = get(state, 'formData.cardType');

    return find(keyCards, { uuid: cardType }) || {};
  },
  [GETTERS.isNextButtonDisabled](state) {
    return !state.formData.deliveryAddress.address;
  },
  [GETTERS.getCustomKeyCards](state) {
    const keyCards = get(state, 'data.keyCardsTypes');

    return reject(keyCards, 'isUbeeqo');
  },
  [GETTERS.isKeycardLoaded](state) {
    return get(state, `STATUS.${STORE_REQUEST_STATUS.loaded}`);
  },
};

export const MUTATIONS = {
  setCsOperatorHasPickUpFlag: 'setCsOperatorHasPickUpFlag',
  setKeycardTypes: 'setKeycardTypes',
  setKeycardType: 'setKeycardType',
  goToInitialStep: 'goToInitialStep',
  goToLinkExistingCard: 'goToLinkExistingCard',
  setAddressDetailsForm: 'setAddressDetailsForm',
  setAddressForm: 'setAddressForm',
  setUserUuid: 'setUserUuid',
  resetData: 'resetData',
  setStatus: 'setStatus',
};

const mutations = {
  setData,
  setError,
  setStatus,
  [MUTATIONS.setCsOperatorHasPickUpFlag](state, csOperatorHasPickUp = null) {
    state.data.csOperatorHasPickUp = csOperatorHasPickUp;
  },
  [MUTATIONS.setKeycardTypes](state, keyCardsTypes = []) {
    state.data.keyCardsTypes = keyCardsTypes;
  },
  [MUTATIONS.setKeycardType](state, newKeyCardType) {
    state.formData.cardType = newKeyCardType;
  },
  [MUTATIONS.goToLinkExistingCard](state) {
    state.data.step = STEPS.linkExistingKeyCard;
  },
  [MUTATIONS.goToInitialStep](state) {
    state.data.step = STEPS.selectKeyCardType;
  },
  [MUTATIONS.setAddressForm](state, address) {
    state.formData.deliveryAddress.address = address;
  },
  [MUTATIONS.setAddressDetailsForm](state, addressDetails) {
    state.formData.deliveryAddress.address_details = addressDetails;
  },
  [MUTATIONS.setUserUuid](state, newValue) {
    state.formData.userUUID = newValue;
  },
  [MUTATIONS.resetData](state) {
    Object.assign(state, cloneDeep(initialModuleState));
  },
};

export const ACTIONS = {
  fetchKeycardsTypes: 'fetchKeycardsTypes',
  goToInitialStep: 'goToInitialStep',
  goToLinkExistingCard: 'goToLinkExistingCard',
  setAddressForm: 'setAddressForm',
  setAddressDetailsForm: 'setAddressDetailsForm',
  setKeycardType: 'setKeycardType',
  submitKeycardFormByMail: 'submitKeycardFormByMail',
  submitKeycardFormLinkCard: 'submitKeycardFormLinkCard',
  submitKeycardFormPickUp: 'submitKeycardFormPickUp',
  setUserUuid: 'setUserUuid',
};

const actions = {
  async [ACTIONS.fetchKeycardsTypes]({
    commit,
    state,
  }, {
    csOperatorUUID,
    force = false,
  }) {
    try {
      const keyCardsTypes = get(state, 'data.keyCardsTypes');

      // When countries array is not empty and force is false
      // we could avoid an unnecessary Api call
      if ((size(keyCardsTypes)) && !force) {
        return;
      }

      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loading);

      const badgesList = await external.badges.getBadgeTechnologiesList('v2', { cs_operator_uuid: csOperatorUUID });

      commit(MUTATIONS.setKeycardTypes, badgesList);
      commit(MUTATIONS.setStatus, STORE_REQUEST_STATUS.loaded);
    } catch (error) {
      logger.message(error.message, LOG_TYPE.error);
    }
  },
  [ACTIONS.setKeycardType]({ commit }, newKeyCardType) {
    commit(MUTATIONS.setKeycardType, newKeyCardType);
  },
  [ACTIONS.goToLinkExistingCard]({ commit }) {
    commit(MUTATIONS.goToLinkExistingCard);
  },
  [ACTIONS.goToInitialStep]({ commit }) {
    commit(MUTATIONS.goToInitialStep);
  },
  [ACTIONS.setAddressForm]({ commit }, address) {
    commit(MUTATIONS.setAddressForm, address);
  },
  [ACTIONS.setAddressDetailsForm]({ commit }, addressDetails) {
    commit(MUTATIONS.setAddressDetailsForm, addressDetails);
  },
  [ACTIONS.submitKeycardFormPickUp]({ state }) {
    const userUuid = state.formData.userUUID;

    const deliveryData = {
      delivery_method: 'pickup',
      address: null,
    };

    return external.badges.postUbeeqoBadge(userUuid, deliveryData);
  },
  [ACTIONS.submitKeycardFormLinkCard]({ state = {}, commit }) {
    const {
      cardType: badgeTechnologyUuid,
      userUUID: userUuid,
    } = state.formData;

    return runAsyncFlow(commit, {
      request: external.badges.postCustomBadgeToUser,
      params: [userUuid, badgeTechnologyUuid],
      scope: SCOPE.linkCard,
    });
  },
  [ACTIONS.submitKeycardFormByMail]({ state }) {
    const userUuid = state.formData.userUUID;

    const {
      address,
      address_details: addressDetails,
    } = state.formData.deliveryAddress;

    const deliveryData = {
      delivery_method: 'mail',
      address: `${address} , ${addressDetails}`,
    };

    return external.badges.postUbeeqoBadge(userUuid, deliveryData);
  },
  [ACTIONS.setUserUuid]({ commit }, newValue) {
    commit(MUTATIONS.setUserUuid, newValue);
  },

};

export const store = {
  namespaced: true,
  state: { ...cloneDeep(initialModuleState) },
  mutations,
  actions,
  getters: storeGetters,
};
