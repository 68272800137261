<template>
  <div class="ResetPassword d-flex flex-row h-100 emobg-background-color-white">
    <Logo
      class="ResetPassword__logo--primary position-absolute d-lg-block d-none"
      :variant="logoType"
    />

    <div
      class="ResetPassword__left-side d-lg-flex d-none ecod-gradient-background"
      data-test-id="left_panel-container"
    >
      <div
        class="w-100"
        :style="{
          backgroundImage: leftImageUrl,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom left',
        }"
      />
    </div>

    <div class="ResetPassword__right-side position-relative d-flex flex-column align-items-start overflow-scroll">
      <NotificationListManager class="fixed-top" />

      <div class="text-center full-width">
        <Logo class="ResetPassword__logo--responsive position-relative d-lg-none d-block py-4" />
      </div>

      <div class="full-width h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="RightSide__wrapper d-flex flex-wrap justify-content-center p-lg-0 px-4 w-100">
          <span
            v-if="!isForced"
            class="w-100"
          >
            <h4
              class="emobg-font-x-large emobg-font-weight-bold mb-3 w-100"
              v-html="$t('refactor.request_reset_password.title')"
            />

            <div
              class="mb-5 w-100"
              v-html="$t('refactor.request_reset_password.text')"
            />
          </span>

          <span v-else>
            <h4
              class="emobg-font-x-large emobg-font-weight-bold mb-3 w-100"
              v-html="$t('refactor.force_request_reset_password.title')"
            />

            <div
              class="mb-5 w-100"
              v-html="$t('refactor.force_request_reset_password.text', { operator: appName })"
            />
          </span>

          <ui-form
            class="w-100"
            validate
          >
            <ui-validate @status="({ detail }) => formStatus = detail">
              <ui-text-input
                v-validate.blurinput="{
                  isEmail: {
                    message: $t('email_input.invalid_email'),
                  },
                  isRequired: {
                    message: $t('refactor.company_signup.errors.mandatory'),
                    whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
                  },
                }"
                :label="$t('refactor.request_reset_password.input_label')"
                :placeholder="$t('refactor.request_reset_password.input_placeholder')"
                :type="INPUT_TYPES.email"
                :value="email"
                data-test-id="email-input"
                class="w-100 d-block mb-5"
                name="email"
                @blurinput="({ detail }) => setEmail(detail)"
                @focusinput="resetInputValidation"
              />

              <ui-button
                v-bind="fetchButtonSpecs()"
                :disabled="!isFormValid"
                :loading="isLoading"
                :size="SIZES.large"
                data-test-id="request_reset_password-button"
                class="pb-lg-0 pb-6 d-block"
                @clickbutton="onClickConfirmEmail()"
              >
                {{ $t('refactor.request_reset_password.button_text') }}
              </ui-button>
            </ui-validate>
          </ui-form>
        </div>
      </div>
    </div>

    <FeedbackModalComponent
      :is-open="modal.isOpen"
      v-bind="modal"
    />
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import get from 'lodash/get';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useStore } from 'vuex-composition-helpers/dist';
import { Validate } from '@emobg/vue-base';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import { useTranslations } from '@/composable/App/Translations';
import config from '@/config';
import { FORGOTTEN_PASSWORD } from '@/constants/cookies.const';
import { genericErrorArgs } from '@/constants/defaultModalArgs';
import Logo from '@/components/Logo/Logo';
import { resetPasswordSuccess } from '@/utils/publicImages';
import { SET_EMAIL_REQUEST_RESET_PASSWORD } from '@/vue/refactor/core/store/reset-password/mutations';
import { REQUEST_RESET_PASSWORD } from '@/vue/refactor/core/store/reset-password/actions';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, LOGOS_KEYNAMES, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { useTheme } from '@/composable/Theme/useTheme';
import { RESET_PASSWORD, RESET_PASSWORD_PATH } from './constants/modules';
import { resetInputValidation } from '../utils/validation.util';

export default {
  name: 'RequestResetPassword',
  components: {
    FeedbackModalComponent,
    NotificationListManager,
    Logo,
  },

  directives: {
    Validate,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { $t } = useTranslations();
    const { fetchButtonSpecs } = useTheme();

    const logoType = LOGOS_KEYNAMES.CONTRAST_LOGO;
    const isForced = ref(Cookie.get(FORGOTTEN_PASSWORD.forceResetPassword) === 'true');
    const formStatus = ref({
      areAllValidated: false,
      isFormReady: false,
      isTouched: false,
      isValid: false,
    });
    const modal = ref({
      isOpen: false,
    });

    const email = computed(() => get(store, `state.${RESET_PASSWORD_PATH}.requestResetPassword.email`));
    const isFormValid = computed(() => (get(formStatus, 'value.isValid') || false) && (get(formStatus, 'value.areAllValidated') || false));
    const isLoading = computed(() => get(store, `state.${RESET_PASSWORD_PATH}.requestResetPassword.apiRunning`));
    const redirectUrl = computed(() => route.query.redirect || '/');
    const userSignUpImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2c);
    const leftImageUrl = `url(${userSignUpImage})`;
    const { appName } = config.data;

    const requestResetPasswordAction = async emailValue => store.dispatch(`${RESET_PASSWORD}/${REQUEST_RESET_PASSWORD}`, emailValue);
    const setEmail = emailValue => store.commit(`${RESET_PASSWORD}/${SET_EMAIL_REQUEST_RESET_PASSWORD}`, emailValue);
    const onClickConfirmEmail = async () => {
      try {
        await requestResetPasswordAction(email.value);

        modal.value = {
          description: $t('refactor.request_reset_password.success_modal.description'),
          image: resetPasswordSuccess,
          isOpen: true,
          primaryCallToAction: () => {
            Cookie.remove(FORGOTTEN_PASSWORD.forceResetPassword);
            window.location = redirectUrl.value;
            modal.value = { ...modal, isOpen: false };
          },
          primaryCallToActionText: $t('buttons.got_it'),
          title: $t('refactor.request_reset_password.success_modal.title'),
        };

        setEmail('');
      } catch ({ response }) {
        modal.value = {
          ...genericErrorArgs($t),
          description: $t('refactor.request_reset_password.error_modal.description'),
          isOpen: true,
          primaryCallToAction: () => {
            modal.value = { ...modal, isOpen: false };
          },
          primaryCallToActionText: $t('buttons.got_it'),
        };
      }
    };
    return {
      appName,
      email,
      fetchButtonSpecs,
      formStatus,
      isForced,
      isFormValid,
      isLoading,
      modal,
      logoType,
      onClickConfirmEmail,
      resetInputValidation,
      setEmail,
      leftImageUrl,
    };
  },
};

</script>

<style lang="scss" scoped>
@import "~@emobg/sass/bootstrap/variables";
@import "~@emobg/sass/colors/variables";
$left-side-width: 450px !default;
$right-side-wrapper-max-width: 456px !default;

.ResetPassword {
  flex: 1;
  min-height: 100vh;

  &__logo {
    &--primary {
      top: map-get($spacers, 4);
      left: map-get($spacers, 4);
    }

    &--responsive {
      margin: 0 auto;
      fill: map-get($motion-color-palette, corporate-main);
    }
  }

  &__left-side {
    flex: 0 0 $left-side-width;
    background-position: center;
    background-size: cover;
  }

  &__right-side {
    flex: 2;
  }

  .LeftSide {
    &__background {
      bottom: 0;
      width: $left-side-width;
      height: 350px;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(238, 238, 238, 0));
    }
  }

  .RightSide {
    &__wrapper {
      max-width: $right-side-wrapper-max-width;
    }
  }
}
</style>
