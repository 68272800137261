const routes = {
  step1: 'SignUp_Company_Step-1',
  step2: 'SignUp_Company_Step-2',
  step3: 'SignUp_Company_Step-3',
  user: 'SignUp_User',
  userStep1: 'SignUp_User_Step-1',
  userStep2: 'SignUp_User_Step-2',
  userStep3: 'SignUp_User_Step-3',
  userInvitationStep1: 'SignUp_User_Invitation-Step-1',
  userInvitationStep2: 'SignUp_User_Invitation-Step-2',
  userInvitationStep3: 'SignUp_User_Invitation-Step-3',
  preregisterInvitationStep1: 'SignUp_User_Preregister_Invitation-Step-1',
  preregisterInvitationStep2: 'SignUp_User_Preregister_Invitation-Step-2',
  preregisterInvitationStep3: 'SignUp_User_Preregister_Invitation-Step-3',
  business: 'SignUp_B2B',
  businessInvitationStep1: 'SignUp_New_B2b_Step-1',
  businessPreregister: 'SignUp_New_Preregistered_B2b_Step-1',
};

export default routes;
