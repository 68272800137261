/*
* we will go to a per-version api methods file,
* so this file will be deprecated in favor of
* provider/version/api/paymentMethodsApi
* i.e. paymentMethodsProviders/Adyen/3.23.0/api/paymentMethodsApi
*/
import { external } from '@emobg/web-api-client';

export const getOriginKey = originSource => external.paymentsProvider.getOriginKey(originSource);

export const storePaymentMethodByUUID = (customerType, uuid) => external.paymentsUser.getStorePaymentMethod(customerType, uuid);

export const authenticateRedirect = payload => external.paymentsUser.postAuthenticate3DS2Redirect(payload);

export const authenticateCompanyRedirect = (
  payload,
  companyUuid,
  paymentMethodUuid,
) => external.paymentsCompany.postAuthenticate3DS2Redirect(payload, companyUuid, paymentMethodUuid);

export const authenticateChallenge = (payload, version = 'v3') => external.paymentsUser.postAuthenticate3DS2Challenge(payload, version);

export const redirectResult = reference => external.paymentsProvider.getRedirectResult(reference);

export const confirmRedirect = payload => external.paymentsProvider.postConfirmRedirect(payload);

export const redirectBookingPaymentResult = bookingInvoiceUuid => external.paymentsProvider.getBookingPaymentResult(bookingInvoiceUuid);
