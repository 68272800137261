export class Company {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.commercial_name = data.commercial_name;
    this.fiscal_name = data.fiscal_name;
    this.fiscal_number = data.fiscal_number;
    this.country = data.country;
    this.countryCode = data.country_code;
    this.address = data.address;
    this.address_details = data.address_details;
    this.phone = data.phone;
    this.size = data.size;
    this.company_fleets = data.fleets;
    this.allows_personal_use = data.allows_personal_use_open;
    this.allows_business_use_open = data.allows_business_use_open;
    this.allows_personal_use_dedicated = data.allows_personal_use_dedicated;
    this.allows_business_use_dedicated = true;
    this.address_street_3 = data.address_street_3;
    this.allow_carpooling = !!data.allow_carpooling;
    this.cs_operator_uuid = data.cs_operator;
    this.dedicated_cs_operator_uuid = data.dedicated_fleet_cs_operator;
    this.cost_allocation = data.cost_allocation || false;
    this.isAdditionalDriverAllowed = data.allows_additional_driver;
    this.codice_fiscale = data.codice_fiscale;
    this.siret_number = data.siret_number;
  }

  getUUID() {
    return this.uuid;
  }

  getCommercialName() {
    return this.commercial_name;
  }

  getAddress() {
    return this.address;
  }

  getAddressDetails() {
    return this.address_details;
  }

  getPhone() {
    return this.phone;
  }

  getSize() {
    return this.size;
  }

  getFleets() {
    return this.company_fleets;
  }

  hasOpenFleet() {
    return !!this.company_fleets.open;
  }

  hasDedicatedFleet() {
    return this.company_fleets ? !!this.company_fleets.dedicated : false;
  }

  getAddressStreet() {
    return this.address_street_3;
  }

  getAllowsPersonalUse() {
    return this.allows_personal_use;
  }

  setCommercialName(value) {
    this.commercial_name = value;
  }

  setFiscalName(value) {
    this.fiscal_name = value;
  }

  setFiscalNumber(value) {
    this.fiscal_number = value;
  }

  setCountry(value) {
    this.country = value;
  }

  setAddress(value) {
    this.address = value;
  }

  setAddressDetails(value) {
    this.address_details = value;
  }

  setPhone(value) {
    this.phone = value;
  }

  setSize(value) {
    this.size = value;
  }

  setFleets(value) {
    this.company_fleets = value;
  }

  setAddressStreet(value) {
    this.address_street_3 = value;
  }

  setAllowsPersonalUse(value) {
    this.allows_personal_use = value;
  }

  userHasCompany() {
    return this.data && this.data.getUUID();
  }

  canUseCarpooling() {
    return this.allow_carpooling;
  }

  getDedicatedFleetCSOperatorUUID() {
    return this.dedicated_cs_operator_uuid;
  }

  getCSOperatorUUID() {
    return this.cs_operator_uuid;
  }

  isCostAllocationConfigurable() {
    return this.cost_allocation;
  }

  static create(attr) {
    return new Company(attr);
  }
}

export default Company;

