const routes = {
  accountSettings: 'account-settings',
  personalInformation: 'account-settings.personal-information',
  drivingLicense: 'account-settings.driving-license',
  badge: 'account-settings.badge',
  paymentMethods: 'account-settings.payment-methods',
  notifications: 'account-settings.notifications',
};

export default routes;
