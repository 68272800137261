import { BOOKING_TYPES } from '@/constants/bookingTypes';

export class BookingType {
  constructor(data) {
    this.code = data.code;
    this.name = data.name;
    this.description = data.description;
  }

  getCode() {
    return this.code;
  }

  static create(attr) {
    return new BookingType(attr);
  }
}

BookingType.carsharingCode = BOOKING_TYPES.carsharing;
BookingType.longDistanceCode = BOOKING_TYPES.longDistance;
BookingType.intervention = BOOKING_TYPES.intervention;

export default BookingType;
