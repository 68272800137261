const routes = {
  home: 'booking',
  oldHomeName: 'Home',
  bookingSummary: 'bookings.summary',
  myBookings: 'bookings.myBookings',
  myBookingsActive: 'bookings.myBookings.active',
  myBookingsHistory: 'bookings.myBookings.history',
  myBookingsLogbook: 'bookings.myBookings.logbook',
  carpooling: 'carpooling',
  logbook: 'bookings.logbook',
  myInterventions: 'bookings.myInterventions',
  myInterventionsActive: 'bookings.myInterventions.active',
  myInterventionsHistory: 'bookings.myInterventions.history',
};

export default routes;
