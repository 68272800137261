import routes from './routes-names';

const LegalDocuments = () => import(/* webpackChunkName: "documents" */'@/domains/Document/Legal/LegalDocuments');
const ShowLegalDocument = () => import(/* webpackChunkName: "documents" */'@/domains/Document/Legal/ShowLegalDocument');

export const IndexLegalDocumentsRoute = {
  path: '/legal',
  name: routes.legalDocuments,
  component: LegalDocuments,
  meta: {
    responsive: true,
    requireLoader: true,
  },
};

export const TermsOfUseRoute = {
  path: '/legal/terms-of-use',
  name: routes.international_terms_of_use,
  component: LegalDocuments,
  meta: {
    responsive: true,
    requireLoader: true,
    tab: ShowLegalDocument,
  },
};

export const TermsAndConditionsRoute = {
  path: '/legal/terms-and-conditions',
  name: routes.carsharing_t_c,
  component: LegalDocuments,
  meta: {
    responsive: true,
    requireLoader: true,
    tab: ShowLegalDocument,
  },
};

export const PrivacyPolicyRoute = {
  path: '/legal/privacy-policy',
  name: routes.privacy_policy,
  component: LegalDocuments,
  meta: {
    responsive: true,
    requireLoader: true,
    tab: ShowLegalDocument,
  },
};

export default [
  IndexLegalDocumentsRoute,
  TermsOfUseRoute,
  TermsAndConditionsRoute,
  PrivacyPolicyRoute,
];
