import omit from 'lodash/omit';

import getInitialState from './_state';

export const MUTATIONS = {
  AVAILABILITY_LOADING: 'AVAILABILITY_LOADING',

  SET_OLD_CITY_VALUE: 'SET_OLD_CITY_VALUE',

  SET_OLD_PROFILE_VALUE: 'SET_OLD_PROFILE_VALUE',

  SET_OLD_BOOKING_TYPE_VALUE: 'SET_OLD_BOOKING_TYPE_VALUE',

  SET_OLD_START_VALUE: 'SET_OLD_START_VALUE',

  SET_OLD_END_VALUE: 'SET_OLD_END_VALUE',

  ADD_BEFORE_ACTION: 'ADD_BEFORE_ACTION',

  ADD_AFTER_ACTION: 'ADD_AFTER_ACTION',

  SET_AVAILABILITY_RESPONSE: 'SET_AVAILABILITY_RESPONSE',

  RESET_STATE: 'RESET_STATE',

  SET_EMPTY_MESSAGE: 'SET_EMPTY_MESSAGE',

  WAITING_GEOLOCATION: 'WAITING_GEOLOCATION',

  SET_TERMS_CONDITIONS: 'SET_TERMS_CONDITIONS',

  SET_AVAILABILITY_FILTERS: 'SET_AVAILABILITY_FILTERS',

  SET_OLD_AVAILABILITY_FILTERS: 'SET_OLD_AVAILABILITY_FILTERS',

  SET_FILTERS_ARE_COLLAPSED: 'SET_FILTERS_ARE_COLLAPSED',

  SET_NUMBER_FILTERS_NOT_COLLAPSED: 'SET_NUMBER_FILTERS_NOT_COLLAPSED',

  OPEN_FILTERS_COLLAPSED_PANEL: 'OPEN_FILTERS_COLLAPSED_PANEL',

  SET_EMPLOYEE_PROFILE: 'SET_EMPLOYEE_PROFILE',
};

export default {
  [MUTATIONS.AVAILABILITY_LOADING](state, loading) {
    return Object.assign(state, {
      loading,
    });
  },

  [MUTATIONS.SET_OLD_CITY_VALUE](state, city) {
    return Object.assign(state.oldValues, {
      city,
    });
  },

  [MUTATIONS.SET_OLD_PROFILE_VALUE](state, profile) {
    return Object.assign(state.oldValues, {
      profile,
    });
  },

  [MUTATIONS.SET_OLD_BOOKING_TYPE_VALUE](state, bookingType) {
    return Object.assign(state.oldValues, {
      bookingType,
    });
  },

  [MUTATIONS.SET_OLD_START_VALUE](state, start) {
    return Object.assign(state.oldValues, {
      start,
    });
  },

  [MUTATIONS.SET_OLD_END_VALUE](state, end) {
    return Object.assign(state.oldValues, {
      end,
    });
  },

  [MUTATIONS.ADD_BEFORE_ACTION](state, beforeActions) {
    Object.assign(state, {
      beforeActions,
    });
  },

  [MUTATIONS.ADD_AFTER_ACTION](state, afterActions) {
    Object.assign(state, {
      afterActions,
    });
  },

  [MUTATIONS.SET_AVAILABILITY_RESPONSE](state, availabilityResponse) {
    let toAssign = availabilityResponse;

    if (!toAssign) {
      toAssign = {
        locations: null,
        start_availability: null,
        end_availability: null,
      };
    }

    state.previousAvailables = { ...state.availables };

    return Object.assign(state.availables, toAssign);
  },

  [MUTATIONS.RESET_STATE](state, omitted) {
    const initState = getInitialState();
    const newState = omit(initState, omitted);
    return Object.assign(state, newState);
  },

  [MUTATIONS.SET_EMPTY_MESSAGE](state, message) {
    return Object.assign(state, {
      emptyMessage: message,
    });
  },

  [MUTATIONS.WAITING_GEOLOCATION](state, status) {
    return Object.assign(state, {
      waitingGeolocation: !!status,
    });
  },

  [MUTATIONS.SET_TERMS_CONDITIONS](state, status = true) {
    state.availables.legal_documents['t_&_c_accepted'] = status;
  },

  [MUTATIONS.SET_AVAILABILITY_FILTERS](state, filters = []) {
    state.filters = filters;
  },

  [MUTATIONS.SET_OLD_AVAILABILITY_FILTERS](state, {
    vehicleCategory = [],
    transmission = [],
    fuelType = [],
    seats = [],
    doors = [],
    bookingMode = [],
  }) {
    Object.assign(state.oldValues, {
      vehicleCategory,
      transmission,
      fuelType,
      seats,
      doors,
      bookingMode,
    });
  },

  [MUTATIONS.SET_FILTERS_ARE_COLLAPSED](state, payload) {
    state.filtersAreCollapsed = payload;
  },

  [MUTATIONS.SET_NUMBER_FILTERS_NOT_COLLAPSED](state, num) {
    state.numberFiltersNotCollapsed = num;
  },

  [MUTATIONS.OPEN_FILTERS_COLLAPSED_PANEL](state, payload) {
    state.openFiltersCollapsedPanel = payload;
  },

  [MUTATIONS.SET_EMPLOYEE_PROFILE](state, employeeProfile) {
    state.employee = employeeProfile;
  },

};
