import forEach from 'lodash/forEach';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import { VALIDATION_CLASSES } from '@emobg/vue-base';

/**
 * Removes validation classes and error messages from an input when invoked through an event handler
 * @param {*} event The event to extract the target element from
 */
export const resetInputValidation = event => {
  const input = get(event, 'currentTarget');

  const elementsWithValidationClass = invoke(input, 'querySelectorAll', `.${VALIDATION_CLASSES.invalid}, .${VALIDATION_CLASSES.valid}`);
  forEach(elementsWithValidationClass, element => invoke(
    element, 'classList.remove', VALIDATION_CLASSES.valid, VALIDATION_CLASSES.invalid,
  ));

  const errorMessages = invoke(input, 'querySelectorAll', `.${VALIDATION_CLASSES.label}`);
  forEach(errorMessages, errorMessage => invoke(errorMessage, 'remove'));
};
