import routes from './routes-names';

const EmployeeBookingsView = () => import(/* webpackChunkName: "employee-bookings" */'@Profile/Business/EmployeeBookings/EmployeeBookingsView');
const CompanyAdmin = () => import(/* webpackChunkName: "company-admin" */'@Profile/Business/CompanyAdmin/CompanyAdmin');

export const CompanyAdminRoute = {
  path: '/company-admin',
  name: routes.companyAdmin,
  redirect: {
    name: routes.employeeList,
  },
};

export const TariffsRoute = {
  path: '/company-admin/tariffs',
  name: routes.tariffs,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const InvoicesRoute = {
  path: '/company-admin/invoices',
  name: routes.invoices,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const BookingOverviewRoute = {
  path: '/company-admin/booking-overview',
  name: routes.bookingOverview,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const EmployeeListRoute = {
  path: '/company-admin/employees',
  name: routes.employeeList,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const EmployeeBookingsListRoute = {
  path: '/employee-bookings/bookings',
  name: routes.employeeBookingsList,
  component: EmployeeBookingsView,
  meta: {
    requireAuth: true,
  },
};

export const EmployeeBookingRequestsListRoute = {
  path: '/employee-bookings/booking-requests',
  name: routes.employeeBookingRequests,
  component: EmployeeBookingsView,
  meta: {
    requireAuth: true,
  },
};

export const ReportingRoute = {
  path: '/company-admin/reporting',
  name: routes.reporting,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },

};

export const CompanySettingsRoute = {
  path: '/company-admin/settings',
  name: routes.settings,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const PaymentMethodsRoute = {
  path: '/company-admin/payment-methods',
  name: routes.paymentMethods,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export const FleetListRoute = {
  path: '/company-admin/fleet',
  name: routes.fleetList,
  component: CompanyAdmin,
  meta: {
    requireAuth: true,
  },
};

export default [
  CompanyAdminRoute,
  TariffsRoute,
  InvoicesRoute,
  BookingOverviewRoute,
  EmployeeListRoute,
  EmployeeBookingsListRoute,
  EmployeeBookingRequestsListRoute,
  ReportingRoute,
  CompanySettingsRoute,
  PaymentMethodsRoute,
  FleetListRoute,
];
