import { BOOKING_TYPES } from '@/constants/bookingTypes';
import routes from './routes-names';

const HomeView = () => import(/* webpackChunkName: "booking" */'@Bookings/Home');
const BookingSummaryFeatureBridge = () => import(/* webpackChunkName: "booking" */'@Bookings/Summary/BookingSummaryFeatureBridge');
const MyBookingsView = () => import(/* webpackChunkName: "booking" */'@Bookings/MyBookings/MyBookings');
const LogbookView = () => import(/* webpackChunkName: "booking" */'@Bookings/Logbook/LogbookView');

export const HomeRoute = {
  path: '/',
  name: routes.home,
  component: HomeView,
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const BookingRoute = {
  path: '/bookings',
  name: routes.oldHomeName,
  redirect: { name: routes.home },
};

export const BookingSummaryRoute = {
  path: '/bookings/summary/:hash',
  name: routes.bookingSummary,
  component: BookingSummaryFeatureBridge,
  meta: {
    requireAuth: true,
  },
};

export const MyBookingsRoute = {
  path: '/bookings/my-bookings',
  name: routes.myBookings,
  component: MyBookingsView,
  redirect: { name: routes.myBookingsActive },
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const MyBookingsActiveRoute = {
  path: '/bookings/my-bookings/active',
  name: routes.myBookingsActive,
  component: MyBookingsView,
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const MyBookingsHistoryRoute = {
  path: '/bookings/my-bookings/history',
  name: routes.myBookingsHistory,
  component: MyBookingsView,
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const MyBookingsLogbookRoute = {
  path: '/bookings/my-bookings/logbook',
  name: routes.myBookingsLogbook,
  component: MyBookingsView,
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const LogbookRoute = {
  path: '/bookings/logbook',
  name: routes.logbook,
  component: LogbookView,
  props: true,
  meta: {
    requireAuth: true,
  },
};

export const MyInterventionsRoute = {
  path: '/bookings/my-interventions',
  name: routes.myInterventions,
  component: MyBookingsView,
  redirect: { name: routes.myInterventionsActive },
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const MyInterventionsActiveRoute = {
  path: '/bookings/my-interventions/active',
  name: routes.myInterventionsActive,
  component: MyBookingsView,
  props: {
    bookingTypes: [BOOKING_TYPES.intervention],
  },
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export const MyInterventionsHistoryRoute = {
  path: '/bookings/my-interventions/history',
  name: routes.myInterventionsHistory,
  component: MyBookingsView,
  props: {
    bookingTypes: [BOOKING_TYPES.intervention],
  },
  meta: {
    requireLoader: true,
    requireAuth: true,
  },
};

export default [
  HomeRoute,
  BookingRoute,
  MyBookingsRoute,
  BookingSummaryRoute,
  MyBookingsActiveRoute,
  MyBookingsHistoryRoute,
  MyBookingsLogbookRoute,
  LogbookRoute,
  MyInterventionsRoute,
  MyInterventionsActiveRoute,
  MyInterventionsHistoryRoute,
];
