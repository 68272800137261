import { ICONS } from '@emobg/web-components';
import get from 'lodash/get';

import isNil from 'lodash/isNil';
import { PAYMENT_TYPES } from './constants/paymentMethodsHub';

// TODO: move maskIbanNumber to web-utils (https://europcarmobility.atlassian.net/browse/CSREV-2738)
const maskIbanNumber = iban => {
  const ibanTrimmed = iban.replace(/ */g, '');

  return ibanTrimmed.slice(0, 4) + '*'.repeat(ibanTrimmed.length - 8) + ibanTrimmed.slice(-4);
};

const isTypeCard = paymentMethod => paymentMethod?.payment_method_type === PAYMENT_TYPES.card;

const isInternal = paymentMethod => paymentMethod?.internal;

const isBankTransfer = paymentMethod => paymentMethod?.payment_method_type === PAYMENT_TYPES.bankTransfer;

const isB2BAllowed = paymentMethod => !isNil(paymentMethod) && (isBankTransfer(paymentMethod) || !isInternal(paymentMethod));

const isAllowedInB2BPaymentMethods = paymentMethod => (isB2BAllowed(paymentMethod));

const paymentMethodIcon = paymentMethod => ((isTypeCard(paymentMethod))
  ? paymentMethod.type
  : get(ICONS, 'cards.plain'));

export {
  maskIbanNumber, paymentMethodIcon, isTypeCard, isAllowedInB2BPaymentMethods
};
