import routes from './routes-names';

const CarpoolingBookingsList = () => import(/* webpackChunkName: "business" */'@/domains/Carpooling/CarpoolingBookingsListView');

const PaymentMethodsTab = () => import(/* webpackChunkName: "business" */'@Shared/Payments/PaymentMethodsTab');
const CostAllocationTab = () => import(/* webpackChunkName: "business" */'@/components/Profile/tabs/CostAllocations/CostAllocationTab');

const BusinessProfile = () => import(/* webpackChunkName: "business" */'../BusinessProfile');
const EmployeePolicyView = () => import(/* webpackChunkName: "business" */'../ShowEmployeePolicy');

export const BusinessProfileRoute = {
  path: '/business-profile',
  name: routes.businessProfile,
};

export const PaymentMethodsRoute = {
  path: '/business-profile/payment-methods',
  name: routes.paymentMethods,
  component: BusinessProfile,
  meta: {
    requireAuth: true,
    tab: PaymentMethodsTab,
  },
};

export const CostAllocationsRoute = {
  path: '/business-profile/cost-allocations',
  name: routes.costAllocations,
  component: BusinessProfile,
  meta: {
    requireAuth: true,
    tab: CostAllocationTab,
  },
};

export const CarpoolingBookingsRoute = {
  path: '/business-profile/carpooling',
  name: routes.carpooling,
  component: CarpoolingBookingsList,
  meta: {
    requireAuth: true,
  },
};

export const EmployeePolicyRoute = {
  path: '/business-profile/employee-policy',
  name: routes.employeePolicy,
  component: EmployeePolicyView,
  meta: {
    requireAuth: true,
  },
};

export default [
  BusinessProfileRoute,
  PaymentMethodsRoute,
  CostAllocationsRoute,
  CarpoolingBookingsRoute,
  EmployeePolicyRoute,
];
