import getInitialState from './state';

export const TYPE = {

  SET_PROFILE_COLLECTION: 'SET_PROFILE_COLLECTION',

  SET_CURRENT_PROFILE: 'SET_CURRENT_PROFILE',

  RESET_STATE: 'RESET_STATE',

  SELECT_PROFILE: 'SELECT_PROFILE',
};

export default {

  [TYPE.SET_PROFILE_COLLECTION](state, collection) {
    return Object.assign(state, {
      profileCollection: collection,
    });
  },

  [TYPE.SET_CURRENT_PROFILE](state, profile) {
    return Object.assign(state, {
      currentProfile: profile,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },

  [TYPE.SELECT_PROFILE](state, profile) {
    return Object.assign(state, {
      currentProfileSelected: profile,
    });
  },
};
