class Notification {
  constructor(params = {
    id: (parseInt((Math.random() * 10000).toFixed(), 10)),
    message: 'Internal error',
    type: Notification.ERROR,
    position: Notification.LEFT,
    dismissable: true,
    actionText: 'OK',
    action: () => {
      // Nothing to do
    },
  }) {
    this.id = params.id;
    this.text = params.message;
    this.type = params.type;
    this.position = params.position;
    this.dismiss = params.dismissable;
    this.softDelete = false;
    this.timing = 4000;
    this.actionText = params.actionText;
    this.actionCallback = params.action;
  }

  getId() {
    return this.id;
  }

  getMessage() {
    return this.text;
  }

  getType() {
    return this.type;
  }

  getPosition() {
    return this.position;
  }

  isDismissable() {
    return this.dismiss;
  }

  isSoftDeleted() {
    return this.softDelete;
  }

  getActionText() {
    return this.actionText;
  }

  setActionText(text) {
    this.actionText = text;
  }

  setActionCallback(callback) {
    this.actionCallback = callback;
  }

  doAction() {
    this.actionCallback();
  }

  /* FLUENT API */

  static create(message = '') {
    const notification = new Notification();
    notification.message(message);
    return notification;
  }

  error() {
    this.type = Notification.ERROR;
    return this;
  }

  warning() {
    this.type = Notification.WARNING;
    return this;
  }

  success() {
    this.type = Notification.SUCCESS;
    return this;
  }

  info() {
    this.type = Notification.INFO;
    return this;
  }

  centered() {
    this.position = Notification.CENTER;
    return this;
  }

  left() {
    this.position = Notification.LEFT;
    return this;
  }

  message(message) {
    this.text = message;
    return this;
  }

  dismissable(value = true) {
    this.dismiss = value;
    return this;
  }

  undismissable() {
    this.dismiss = false;
    return this;
  }

  delete() {
    this.softDelete = true;
    return this;
  }

  action(text, callback) {
    this.actionText = text || this.actionText;
    this.actionCallback = callback || this.actionCallback;
    return this;
  }
}

Notification.ERROR = 'error';
Notification.LEFT = 'left';
Notification.CENTER = 'center';
Notification.WARNING = 'warning';
Notification.SUCCESS = 'success';
Notification.INFO = 'info';

export default Notification;
