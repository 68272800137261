const routes = {
  personalProfile: 'personal-profile',
  insurances: 'personal-profile.insurances',
  invoices: 'personal-profile.invoices',
  paymentMethods: 'personal-profile.payment-methods',
  promotions: 'personal-profile.promotions',
  tariffs: 'personal-profile.tariffs',
};

export default routes;
