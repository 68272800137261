import isBoolean from 'lodash/isBoolean';
import { USER_ROLES } from '@/constants/user.const';

export class UserData {
  constructor(data) {
    this.uuid = data.uuid;
    this.cs_operator_uuid = data.cs_provider_uuid;
    this.defaultCity = {
      uuid: data.default_city_uuid,
      getUUID() {
        return this.uuid;
      },
    };
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.status = data.status;
    this.country = data.country;
    this.locale = data.locale_code;
    this.title = data.title;
    this.gender = data.gender;
    this.novice = !!data.novice;
    this.picture = data.picture;
    this.validDrivenLicense = data.valid_dl;
    this.validIdentity = data.valid_id;
    this.address = data.address;
    this.address_details = data.address_details;
    this.roles = data.roles || [];
    this.permissions = data.permissions || {};
    this.tariff = data.tariff || null;
    this.email_verified = data.email_verified;
    this.company = data.company;
    this.direct_marketing = data.allow_direct_marketing;
    this.pending_logbook = data.pending_logbook;
    this.set_logbook_first_roadblock = data.set_logbook_first_roadblock;
    this.set_logbook_last_roadblock = data.set_logbook_last_roadblock;
    this.trackingId = data.tracking_id;
    // It's not a UserData property,
    // it's a helper to access to these constants
    this.__userRoles = USER_ROLES;
  }

  getUUID() {
    return this.uuid;
  }

  getTariff() {
    return this.tariff;
  }

  isLogbookLast() {
    return this.set_logbook_last_roadblock;
  }

  getCarsharingOperatorUUID() {
    return this.cs_operator_uuid;
  }

  getFirstName() {
    return this.firstName;
  }

  setFirstName(newFirstName) {
    this.firstName = newFirstName;
  }

  getLastName() {
    return this.lastName;
  }

  getFullName() {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getAddress() {
    return this.address;
  }

  getAddressDetails() {
    return this.address_details;
  }

  setLastName(newLastName) {
    this.lastName = newLastName;
  }

  getEmail() {
    return this.email;
  }

  setEmail(newEmail) {
    this.email = newEmail;
  }

  getStatus() {
    return this.status;
  }

  setStatus(newStatus) {
    this.status = newStatus;
  }

  setCountry(newCountry) {
    this.country = newCountry;
  }

  getLocale() {
    return this.locale;
  }

  setLocale(newLocale) {
    this.locale = newLocale;
  }

  getTitle() {
    return this.title;
  }

  setTitle(newTitle) {
    this.title = newTitle;
  }

  setAddress(value) {
    this.address = value;
  }

  setAddressDetails(value) {
    this.address_details = value;
  }

  getRoles() {
    return this.roles;
  }

  hasRole(role) {
    return this.roles.includes(role);
  }

  isEmployee() {
    return this.hasRole(this.getUserRoles().employee);
  }

  getUserRoles() {
    return this.__userRoles;
  }

  getPermissions() {
    return {
      ...this.permissions,
      // When "payment_assignment" is "employee"
      // (company does not want to pay the employee bookings)
      // field "change_user_business_payment_method" will be true
      canChangeBusinessProfilePaymentMethod:
        () => !!this.permissions.change_user_business_payment_method,

      // Company uses Carpooling feature
      canUseCarpooling:
        () => this.company && !!this.company.allows_carpooling,

      // Company uses Cost Allocation feature
      canChangeCostAllocation:
        () => this.company && !!this.company.cost_allocation,
    };
  }

  getCompany() {
    return this.company;
  }

  isUserEmailVerify() {
    return this.email_verified;
  }

  hasPendingLogbook() {
    // When pending_logbook is null or undefined, user
    // has not pending bookings to log.
    if (isBoolean(this.pending_logbook)) {
      return this.pending_logbook;
    }

    return false;
  }

  static create(attr) {
    return new UserData(attr);
  }
}

export default UserData;
