const ROUTES = {
  error500: 'error500',
  notificationCenter: 'notification-center',
  logout: 'logout',
  maintenance: 'maintenance',
  signUpUserStep1: 'SignUp_User_Step-1',
  signUpUserStep2: 'SignUp_User_Step-2',
  signUpUserStep3: 'SignUp_User_Step-3',
  signUpInvitationStep1: 'SignUp_User_Invitation-Step-1',
  signUpInvitationStep3: 'SignUp_User_Invitation-Step-3',
};

export default ROUTES;
