import RequestResetPassword from '../RequestResetPassword';
import resetPasswordRoutes from './routes-names';

export const RequestResetPasswordRoute = {
  path: '/reset-password/request',
  name: resetPasswordRoutes.request,
  component: RequestResetPassword,
  meta: {
    requireAuth: false,
    responsive: true,
  },
};

export const ResetPasswordRoute = {
  path: '/reset-password',
  name: resetPasswordRoutes.index,
  // If someone tries to access via /reset-password?redirect=...
  // it will be redirect to /reset-password/request and Vue Router
  // maintains the query string parameters.
  // It's awesome in this case! :)
  redirect: { name: resetPasswordRoutes.request },
};

export default [
  ResetPasswordRoute,
  RequestResetPasswordRoute,
];

