import routes from './routes-names';

const UserEmailVerificationView = () => import(/* webpackChunkName: "verification" */'@Account/UserEmailVerification/UserEmailVerificationView');

const EmailVerification = '/email-verification';

export const UserEmailVerificationRoute = {
  path: EmailVerification,
  name: routes.UserEmailVerification,
  component: UserEmailVerificationView,
  meta: {
    requireLoader: true,
    requireAuth: true,
    responsive: true,
  },
};

export default [
  UserEmailVerificationRoute,
];
