import moment from 'moment';

export class Insurance {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.name = data.name;
    this.icon = data.icon;
    this.internal_name = data.internal_name;
    this.fully_covered = data.fully_covered;
    this.expires_at = data.expires_at && moment(data.expires_at, 'YYYY-MM-DD HH:mm:ss');
  }

  getUUID() {
    return this.uuid;
  }

  getIcon() {
    return this.icon;
  }

  getInternalName() {
    return this.internal_name;
  }

  getExpiresAt() {
    return this.expires_at;
  }

  static create(attr) {
    return new Insurance(attr);
  }
}

export default Insurance;
