export const BOOKING_STATUS = {
  booked: 'booked',
  cancelled: 'cancelled',
  finished: 'finished',
  preBooked: 'pre_booked',
  pendingPayment: 'pending_payment',
  unknown: 'unknown',
  notUsed: 'not_used',
  incative: 'inactive',
};
