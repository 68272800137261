export class NotificationChannel {
  constructor(data) {
    this.uuid = data.uuid;
    this.type = data.type;
    this.value = data.value;
  }

  getUUID() {
    return this.uuid;
  }

  getType() {
    return this.type;
  }

  getValue() {
    return this.value;
  }

  setType(type) {
    this.type = type;
  }

  setValue(value) {
    this.value = value;
  }

  static create(attr) {
    return new NotificationChannel(attr);
  }
}

export default NotificationChannel;
