export class Tariff {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.name = data.tariff_name;
  }

  getUUID() {
    return this.uuid;
  }

  static create(attr) {
    return new Tariff(attr);
  }
}

export default Tariff;
