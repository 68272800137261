import get from 'lodash/get';
import moment from 'moment';

export class PaymentMethod {
  constructor(data = {}) {
    this.uuid = get(data, 'uuid');
    this.type = get(data, 'type');
    this.number = get(data, 'number');
    this.internal = get(data, 'internal', false);
    this.holder = get(data, 'holder', null);
    this.expiration = (get(data, 'expiry') && moment(get(data, 'expiry'), 'MM/YY').endOf('M').endOf('D')) || null;
    this.active = get(data, 'active', null);
    this.default = get(data, 'default', false);
    this.profiles = get(data, 'userProfile', []);
  }

  getUUID() {
    return this.uuid;
  }

  getType() {
    return this.type;
  }

  getNumber() {
    return this.number;
  }

  isInternal() {
    return this.internal;
  }

  getHolder() {
    return this.holder;
  }

  getExpiration() {
    return this.expiration;
  }

  getExpirationFormatted() {
    return this.expiration ? this.expiration.format('MM/YYYY') : null;
  }

  isDefault() {
    return this.default;
  }

  setDefault(val) {
    this.default = val;
  }

  isActive() {
    return this.active;
  }

  isAssignedTo(profileUUID) {
    return (this.profiles.filter(item => item.uuid === profileUUID).length > 0);
  }

  assignToProfile(profileUUID) {
    this.profiles = [...this.profiles, { uuid: profileUUID }];
  }

  removeToProfile(profileUUID) {
    this.profiles = this.profiles.filter(p => p.uuid !== profileUUID);
  }

  isTypeCard() {
    return !this.isTypeBankTransfer() && !this.isTypeIban();
  }

  exists() {
    if (this.isTypeBankTransfer()) {
      return true;
    }

    const required = !!(this.uuid && this.type && this.number && this.holder);

    if (this.isTypeCard()) {
      return !!(required && this.expiration);
    }

    if (this.isTypeIban()) {
      return required;
    }

    return false;
  }

  static create(attr) {
    return new PaymentMethod(attr);
  }
}

// Deprecated!! Use @/vue/constants/payment-menthod-type.js
PaymentMethod.prototype.CARD = 'card';

// Deprecated!! Use @/vue/constants/payment-menthod-type.js
PaymentMethod.prototype.SEPA = 'sepa';

export default PaymentMethod;
