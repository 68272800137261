import get from 'lodash/get';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';

import { PUBLIC_PATHS } from '../constants/publicPaths.const';

/**
 * Return a boolean if the path got from the URL or passed by parameter
 * matches with the list of public paths to know when a URL requires authentication
 * @param {string} path
 * @returns {boolean}
 */
export const isAuthenticationNeeded = path => {
  const isCustomPathDefined = !isUndefined(path);

  const pathname = isCustomPathDefined ? path : get(window, 'location.pathname');

  const isValidPathname = !!(isString(pathname) && pathname && pathname.startsWith('/'));

  if (!isValidPathname) {
    throw new Error('URL is not defined in isAuthenticationNeeded method');
  }

  return !PUBLIC_PATHS.some(publicPath => new RegExp(publicPath).test(pathname));
};
