export const BOOKING_USAGE_STATUS = {
  created: 'created',
  cancelled: 'cancelled',
  notUsed: 'not_used',
  late: 'late',
  ready: 'ready',
  paused: 'paused',
  opened: 'opened',
  started: 'started',
  finished: 'finished',
  confirmed: 'confirmed',
  waitingEnd: 'waiting_end',
  finishedLate: 'finished_late',
  waitingCancellation: 'waiting_cancellation',
  unknown: 'unknown',
};
