import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { isAllowedInB2BPaymentMethods, isTypeCard } from '../PaymentMethodsUtils';

export const nameSpace = 'PaymentMethodsHubStore';

export default {
  namespaced: true,
  state: {
    paymentMethodsCollectionB2B: [],
    paymentMethodsCollectionB2C: [],

    responseStack: [],

    lastResponse: null,

    source: null,

    hubAction: null,
  },

  getters: {
    getDefaultPaymentMethodUUID: state => ({ isBusinessInCompanyPays, isBusinessProfile, profileUuid }) => {
      let paymentMethod;
      if (isBusinessInCompanyPays) {
        paymentMethod = find(state.paymentMethodsCollectionB2B, 'default');
      } else {
        const collection = isBusinessProfile
          ? state.paymentMethodsCollectionB2B
          : state.paymentMethodsCollectionB2C;

        paymentMethod = find(collection, ({ userProfile }) => find(userProfile, ['uuid', profileUuid]));
      }
      return paymentMethod?.uuid || null;
    },

    getDefaultOppositePaymentMethodUUID: state => ({ isBusinessInCompanyPays, isBusinessProfile, profileUuid }) => {
      if (isBusinessInCompanyPays) {
        return null;
      }

      const collection = isBusinessProfile
        ? state.paymentMethodsCollectionB2B
        : state.paymentMethodsCollectionB2C;

      const method = find(collection, ({ userProfile }) => find(userProfile, ({ uuid }) => uuid !== profileUuid));
      return method?.uuid || null;
    },

    getPaymentMethodByUUID: state => (paymentMethodUUID, { isBusinessProfile }) => (isBusinessProfile
      ? find(state.paymentMethodsCollectionB2B, { uuid: paymentMethodUUID })
      : find(state.paymentMethodsCollectionB2C, { uuid: paymentMethodUUID })),

    isEmptyPaymentMethodsCollection: state => ({ isBusinessProfile }) => (isBusinessProfile
      ? isEmpty(state.paymentMethodsCollectionB2B)
      : isEmpty(state.paymentMethodsCollectionB2C)),
  },

  mutations: {
    SAVE_PAYMENT_METHODS_COLLECTION(state, payload) {
      const { paymentMethodsCollection, profile } = payload;

      const isEmployeePays = profile.isBusinessProfile && !profile.isBusinessInCompanyPays;

      if (profile.isBusinessInCompanyPays) {
        state.paymentMethodsCollectionB2B = filter(paymentMethodsCollection, method => isAllowedInB2BPaymentMethods(method));
      } else {
        state.paymentMethodsCollectionB2C = filter(paymentMethodsCollection, method => isTypeCard(method));
      }

      if (isEmployeePays) {
        state.paymentMethodsCollectionB2B = state.paymentMethodsCollectionB2C;
      }
    },

    SAVE_RESPONSE(state, response) {
      state.responseStack.push(response);
      state.lastResponse = response;
    },

    UPDATE_SOURCE(state, source) {
      state.source = source;
    },

    UPDATE_HUB_ACTION(state, { action, value }) {
      state.hubAction = { action, value };
    },
  },

  actions: {
    savePaymentMethodsCollection({ commit }, payload) {
      commit('SAVE_PAYMENT_METHODS_COLLECTION', payload);
    },

    saveResponse({ commit }, response) {
      commit('SAVE_RESPONSE', response);
    },

    updateSource({ commit }, source) {
      commit('UPDATE_SOURCE', source);
    },

    updateHubAction({ commit }, { action, value }) {
      commit('UPDATE_HUB_ACTION', { action, value });
    },
  },
};
