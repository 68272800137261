export const USER_VALIDATION_STATUS = Object.freeze({
  aboutToExpire: 'about_to_expire',
  ageNotAvailable: 'age_not_available',
  ageUnderRequirements: 'age_under_requirements',
  complete: 'complete',
  expired: 'expired',
  extraDocumentsNeeded: 'extra_documents_needed',
  incomplete: 'incomplete',
  novelDrivingLicense: 'novel_driving_license',
  pending: 'pending',
});

export const REQUIRED_DOCUMENT_STATUS = Object.freeze({
  missing: 'missing',
  pending: 'pending',
  completed: 'completed',
  // Custom required document status used in CorporateValidationComponent.vue
  aboutToExpiry: 'about_to_expiry',
});

export const REQUIRED_DOCUMENT_TYPES = Object.freeze({
  drivingLicense: 'driving_license',
  drivingLicenseReview: 'driving_license_review',
  badge: 'badge',
  restrictiveBadge: 'restrictive_badge',
});

export const RELATED_DOCUMENTS = Object.freeze({
  drivingLicense: 'driving_license',
  drivingLicenseReview: 'driving_license_review',
  keycard: 'keycard',
  none: null,
});
