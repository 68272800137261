import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import reject from 'lodash/reject';

export const NAMESPACE = 'AppNotification';

export default {
  namespaced: true,
  state: {
    activeNotification: {},
    notificationQueue: [],
  },
  mutations: {
    SET_ACTIVE_NOTIFICATION(state, notification) {
      state.activeNotification = notification;
    },
    RESET_NOTIFICATION_QUEUE(state) {
      const queue = cloneDeep(state.notificationQueue);
      const remainingNotifications = reject(queue, queueItem => !!queueItem.isClosable);
      state.notificationQueue = sortBy(remainingNotifications, ['priority']);
      const firstQueueItem = first(state.notificationQueue);
      state.activeNotification = firstQueueItem || {};
    },
    ADD_NOTIFICATION_TO_QUEUE(state, data) {
      const queue = cloneDeep(state.notificationQueue);
      const foundItems = filter(queue, queueItem => (isEqual(queueItem.text, data.text)));
      const firstItem = first(foundItems);
      if (!firstItem) {
        const notification = {
          uuid: `notification-${data.type}-${queue.length}`,
          ...data,
        };
        queue.push(notification);
        state.notificationQueue = sortBy(queue, ['priority']);
      }
    },
    REMOVE_NOTIFICATION_FROM_QUEUE(state, notification) {
      const queue = cloneDeep(state.notificationQueue);
      state.notificationQueue = filter(queue, queueItem => (!isEqual(queueItem.uuid, notification.uuid)));
    },
  },
  actions: {
    addNotificationToQueue({ commit, dispatch }, payload) {
      commit('ADD_NOTIFICATION_TO_QUEUE', payload);
      dispatch('nextNotification');
    },
    onActiveNotificationClose({ commit, dispatch }, notification) {
      commit('REMOVE_NOTIFICATION_FROM_QUEUE', notification);
      dispatch('nextNotification');
    },
    nextNotification({ commit, state }) {
      commit('SET_ACTIVE_NOTIFICATION', first(state.notificationQueue) || {});
    },
    removeAllNotifications({ commit }) {
      commit('RESET_NOTIFICATION_QUEUE');
    },
  },
  getters: {
    getActiveNotification(state) {
      return get(state, 'activeNotification');
    },
  },
};

