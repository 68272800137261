export const USER_STATUS = {
  activated: 'activated',
  anonymized: 'anonymized',
  blacklisted: 'blacklisted',
  blocked: 'blocked',
  blockedUnpaid: 'blocked_unpaid',
  deactivated: 'deactivated',
  incomplete: 'incomplete',
  preRegistered: 'pre_registered',
  rejected: 'rejected',
  unpaid: 'unpaid',
  validated: 'validated',
  waitingForValidation: 'waiting_for_validation',
};
