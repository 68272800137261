import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';

import Profile from '@Account/legacy/Profile';
import Api from '@/vue/api/backoffice';

import { TYPE } from './mutations';

export const ACTIONS = {
  SET_PROFILE_COLLECTION_DATA: 'setProfileCollection',
  updateNotificationChannel: 'updateNotificationChannel',
  refreshProfileInformation: 'refreshProfileInformation',
  setCurrentProfile: 'setCurrentProfile',
  selectProfile: 'selectProfile',
};

export default {

  /**
   *
   * @param context : Vue context
   * @param arr : Array of data
   */
  [ACTIONS.SET_PROFILE_COLLECTION_DATA](context, arr = []) {
    // Transform data in a Handler/Profile object
    const profileCollection = arr.map(data => {
      if (data.constructor === Profile) {
        return data;
      }
      return new Profile(data);
    });

    // Keep this collection (array of Profile) in Store
    context.commit(TYPE.SET_PROFILE_COLLECTION, profileCollection);
  },

  /**
   *
   * @param profile : profileUuid
   * @param type    : type of notification channel (email/phone)
   * @param value   : value to set to that channel
   */
  [ACTIONS.updateNotificationChannel]({ getters }, { profile, type, value }) {
    const currentChannelConfig = getters.channelsByType(type, profile);

    if (isEmpty(get(currentChannelConfig, 'value'))) {
      Api.userCompany.createUserNotificationProfile(profile, { type, value });
    } else {
      Api.userCompany.updateUserNotificationProfile(get(currentChannelConfig, 'uuid'), { value });
    }
  },

  async [ACTIONS.refreshProfileInformation]({ getters, dispatch }) {
    const { profiles } = await Api.userCompany.getUserDetail();
    dispatch('setProfileCollection', profiles);
    const updatedCurrentProfile = find(getters.getProfileCollection, ['uuid', getters.getCurrentProfile.uuid]);
    dispatch('setCurrentProfile', updatedCurrentProfile);
  },

  [ACTIONS.setCurrentProfile](context, profile) {
    context.commit(TYPE.SET_CURRENT_PROFILE, profile);
  },

  [ACTIONS.selectProfile](context, profile) {
    context.commit(TYPE.SELECT_PROFILE, profile);
  },

  resetProfileStore(context) {
    context.commit(TYPE.RESET_STATE);
  },
};
