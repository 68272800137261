import { MFA_ROUTE_NAMES } from './routes-names';

const MFAVerificationView = () => import(/* webpackChunkName: "mfa-verification" */'../MFAVerificationView');

export const MFAVerification = {
  path: '/verification',
  name: MFA_ROUTE_NAMES.mfaVerification,
  component: MFAVerificationView,
  meta: {
    requireAuth: false,
  },
};

export default [
  MFAVerification,
];
