const routes = {
  businessProfile: 'business-profile',
  paymentMethods: 'business-profile.payment-methods',
  notifications: 'business-profile.notifications',
  costAllocations: 'business-profile.cost-allocations',
  carpooling: 'business-profile.carpooling',
  employeePolicy: 'business-profile.employee-policy',
};

export default routes;
