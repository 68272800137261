export default class ModalComponent {
  constructor(component, parameters) {
    this.component = component;
    this.parameters = parameters;
  }

  getComponent() {
    return this.component;
  }

  setComponent(val) {
    this.component = val;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(val) {
    this.parameters = val;
  }
}
