/**
 * Used when user wants to change his booking type
 * @type {string}
 */
export const CHANGE_BOOKING_TYPE = 'CHANGE_BOOKING_TYPE';

/**
 * Used when user wants to change a date
 * @type {string}
 */
export const CHANGE_DATETIMEPICKER = 'CHANGE_DATETIMEPICKER';

/**
 * Used when user wants to bounce map
 * @type {string}
 */
export const MAP_BOUNCING = 'MAP_BOUNCING';

/**
 * Used when user wants to zoom map changes
 * @type {string}
 */
export const ZOOM_MAP_CHANGING = 'ZOOM_MAP_CHANGING';

/**
 * Used when Marker is clicked
 * @type {string}
 */
export const MARKER_SELECTED = 'MARKER_SELECTED';

/**
 * Launched when user clicks on Book car button
 * @type {string}
 */
export const SHOW_BOOKING_SUMMARY = 'SHOW_BOOKING_SUMMARY';

/**
 * Used when book button of location list is clicked
 * @type {string}
 */
export const GO_BOOKING_CONFIRM = 'GO_BOOKING_CONFIRM';
/**
 * Used when Map is loaded. This event is fired when 'tilesloaded'
 * of Map instance is launched.
 * @type {string}
 */
export const MAP_LOADED = 'MAP_LOADED';

/**
 * Event fired when user saves his payment method in current profile store
 * @type {string}
 */
export const SAVED_PAYMENT_METHOD_IN_PROFILE = 'SAVED_PAYMENT_METHOD_IN_PROFILE';
/**
 * Event fired when user creates a new payment method from a form
 * @type {string}
 */
export const NEW_PAYMENT_METHOD_CREATED = 'NEW_PAYMENT_METHOD_CREATED';
/**
 * Event is fired when user press more info link from insurance upgrade
 * @type {string}
 */
export const OPEN_INSURANCE_MORE_INFO_MODAL = 'OPEN_INSURANCE_MORE_INFO_MODAL';
/**
 * Event is fired when a booking has been confirmed
 * @type {string}
 */
export const CONFIRM_BOOKING_RESERVATION = 'CONFIRM_BOOKING_RESERVATION';
/**
 * Event is fired when a booking is cancelled
 * @type {string}
 */
export const CANCEL_BOOKING = 'CANCEL_BOOKING';

/**
 * Event is fired when a prebooking is cancelled
 * @type {string}
 */
export const CANCEL_PREBOOKING = 'CANCEL_PREBOOKING';

/**
 * Event is fired when user selects a new payment method
 * between their payment methods availables
 * @type {string}
 */
export const SELECT_NEW_PAYMENT_METHOD = 'SELECT_NEW_PAYMENT_METHOD';

/**
 * Event is fired when confirms the buy of insurance
 * @type {string}
 */
export const CONFIRM_INSURANCE_BUY = 'CONFIRM_INSURANCE_BUY';

/**
 * Event fired to update my bookings
 * @type {string}
 */
export const UPDATE_ACTIVE_BOOKINGS = 'UPDATE_ACTIVE_BOOKINGS';

/**
 *
 * @type {string}
 */
export const RESET_TARIFF_LIST = 'RESET_TARIFF_LIST';

/**
 * Show error modal page.
 *
 * List of parameters of Modal:
 * @param title: this.$t('modal.signup.error.title')
 * @param buttonText: this.$t('modal.signup.error.try_again'),
 * @param buttonAction() {
            window.location = '/';
          },
 * @param automaticAction() {
            setTimeout(() => {
              window.location = '/';
            }, 5000);
          },
 * @param error,
 *
 * @type {string}
 */
export const SHOW_ERROR_MODAL_PAGE = 'SHOW_ERROR_MODAL_PAGE';

export const SHOW_MUI_NOTIFICATION = 'SHOW_MUI_NOTIFICATION';

export const SHOW_MUI_NOTIFICATION_COST_ALLOCATION = 'SHOW_MUI_NOTIFICATION_COST_ALLOCATION';
