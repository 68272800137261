import get from 'lodash/get';
import find from 'lodash/find';
import result from 'lodash/result';
import size from 'lodash/size';

import { isBusinessProfile, isPersonalProfile } from '@/helpers/user/profiles';
import { PROFILE_TYPES } from '@/constants/profiles.const';

export const GETTERS = {
  getProfileCollection: 'getProfileCollection',
  getCurrentProfile: 'getCurrentProfile',
  getProfileByUUID: 'getProfileByUUID',
  hasBusinessProfile: 'hasBusinessProfile',
  hasPersonalProfile: 'hasPersonalProfile',
  isPersonalProfileActive: 'isPersonalProfileActive',
  isBusinessProfileActive: 'isBusinessProfileActive',
  channelsByType: 'channelsByType',
  canUseCostAllocation: 'canUseCostAllocation',
  getCurrentProfileCompanyUuid: 'getCurrentProfileCompanyUuid',
  getCurrentProfileType: 'getCurrentProfileType',
  getCurrentProfileUuid: 'getCurrentProfileUuid',
  hasOnlyBusinessProfile: 'hasOnlyBusinessProfile',
  getCurrentProfileAlias: 'getCurrentProfileAlias',
  getSelectedProfile: 'getSelectedProfile',
};

export default {
  [GETTERS.getProfileCollection](state) {
    return state.profileCollection;
  },

  [GETTERS.getCurrentProfile](state) {
    return state.currentProfile;
  },

  [GETTERS.getCurrentProfileType](state) {
    return get(state.currentProfile, 'type');
  },

  [GETTERS.getCurrentProfileUuid](state) {
    return get(state.currentProfile, 'uuid');
  },

  [GETTERS.getCurrentProfileAlias](state) {
    return get(state.currentProfile, 'alias');
  },

  [GETTERS.getProfileByUUID](state) {
    return uuid => find(state.profileCollection, { uuid });
  },

  [GETTERS.hasBusinessProfile](state) {
    return find(state.profileCollection, { type: PROFILE_TYPES.business }) || false;
  },

  [GETTERS.hasPersonalProfile](state) {
    return find(state.profileCollection, { type: PROFILE_TYPES.personal }) || false;
  },

  [GETTERS.isPersonalProfileActive](state) {
    return isPersonalProfile(get(state, 'currentProfile.type'));
  },

  [GETTERS.isBusinessProfileActive](state) {
    return isBusinessProfile(get(state, 'currentProfile.type'));
  },

  [GETTERS.channelsByType](state) {
    return (type, profileUuid) => {
      const profile = profileUuid
        ? find(state.profileCollection, ['uuid', profileUuid])
        : state.currentProfile;

      return find(profile.notificationChannels, ['type', type]);
    };
  },

  [GETTERS.canUseCostAllocation](state, getters) {
    return getters.isBusinessProfileActive
      && result(state, 'currentProfile.getCompanyData.isCostAllocationConfigurable');
  },

  [GETTERS.getCurrentProfileCompanyUuid](state) {
    return result(state, 'currentProfile.getCompanyData.getUUID');
  },

  [GETTERS.hasOnlyBusinessProfile](_state, getters) {
    return size(getters.getProfileCollection) === 1 && getters.hasBusinessProfile;
  },

  [GETTERS.getSelectedProfile](state) {
    return state.currentProfileSelected;
  },
};
