import { useAuth } from '@/composable/Api/Auth/auth';
import routes from './routes-names';

const Error500 = () => import(/* webpackChunkName: "error" */'@Shared/Errors/Error500View');
const Maintenance = () => import(/* webpackChunkName: "maintenance" */'@Shared/Maintenance/MaintenanceComponent');

export const Error500Route = {
  path: '/error',
  name: routes.error500,
  component: Error500,
};

export const NotificationCenterRoute = {
  path: '/communication-preferences',
  name: routes.notificationCenter,
  meta: {
    responsive: true,
  },
};

export const MaintenanceRoute = {
  path: '/maintenance',
  name: routes.maintenance,
  component: Maintenance,
  meta: {
    responsive: true,
    requireAuth: false,
    requireLoader: false,
  },
};

export const LogoutRoute = {
  path: '/impersonate/logout',
  alias: '/logout',
  name: routes.logout,
  beforeEnter() {
    const { logout } = useAuth();

    logout();
  },
  meta: {
    requireLoader: true,
    requireAuth: false,
    responsive: true,
  },
};

export default [
  Error500Route,
  NotificationCenterRoute,
  LogoutRoute,
  MaintenanceRoute,
];
