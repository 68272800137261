import { createCustomStore } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import { getVehicleAvailabilityFromData } from './VehicleAvailabilityUtils';

export const NAMESPACE = 'VehicleAvailability';

export const ACTIONS = {
  getVehicleAvailability: 'getVehicleAvailability',
  setVehicleAvailable: 'setVehicleAvailable',
};

export const GETTERS = {
  isVehicleAvailable: 'IS_VEHICLE_AVAILABLE',
};

export const MUTATIONS = {
  setActiveVehicle: 'SET_ACTIVE_VEHICLE',
};

// TODO [CF-133]: It's neccesary a service to get the availability of one vehicle
// Right now we use a service that gets all vehicles of cs operator for validating only one
const getAvailability = ({ vehicleUuid, ...params }) => external.availability.getAvailability(params)
  .then(result => getVehicleAvailabilityFromData(vehicleUuid, result));

export default createCustomStore(({ runAsyncFlow }) => ({
  getters: {
    [GETTERS.isVehicleAvailable](state) {
      return state.occupation.available;
    },
  },
  actions: {
    async [ACTIONS.getVehicleAvailability]({ commit }, availabilityParams) {
      await runAsyncFlow(commit, {
        request: getAvailability,
        params: [availabilityParams],
      });
    },
    [ACTIONS.setVehicleAvailable]({ commit }) {
      commit(MUTATIONS.setActiveVehicle);
    },
  },
  mutations: {
    [MUTATIONS.setActiveVehicle](state) {
      state.STATUS = {
        ...state.STATUS,
        LOADING: false,
        LOADED: false,
        ERROR: false,
      };
      state.data = {
        ...state.data,
        occupation: {
          available: true,
          bookings: [],
        },
      };
    },
  },
}));

