export const PUBLIC_PATHS = [
  /\/signin[^$]*$/,
  /\/signup[^$]*$/,
  /\/sign-up[^$]*$/,
  /^\/reset-password\/request[^$]*$/,
  /^\/vehicle-damage-report[^$]*$/,
  /^\/legal[^$]*$/,
  /^\/impersonate[^$]*$/,
  /^\/[^$]*\/confirm-invitation-employee[^$]*$/,
  /^\/[^$]*\/confirm-invitation[^$]*$/,
  /^\/[^$]*\/complete-invitation-employee[^$]*$/,
  /^\/[^$]*\/preregistered[^$]*$/,
  /^\/communication-preferences[^$]*$/,
  /^\/phishing-badge[^$]/,
  /^\/maintenance[^$]*$/,
  /^.*\/cloud\.email\.ubeeqo\.com\/PreferenceCenter*/,
  /\/preregister[^$]*$/,
];
