export default class Rules {
  constructor(config) {
    this.config = config;
  }

  getMinDuration() {
    return this.config.getCarsharingDefaultMinBookingDuration();
  }

  getMaxDuration() {
    return this.config.getCarsharingDefaultMaxBookingDuration();
  }

  getMinimumEnd(start) {
    const minimumDuration = this.getMinDuration();

    return moment(start).add(minimumDuration, 'm').subtract(1, 'second');
  }

  getEnd(start) {
    const minimumDuration = this.getMinDuration();

    return moment(start).add(minimumDuration, 'm');
  }

  getMaximumEnd(start) {
    return moment(start).add(this.getMaxDuration(), 'm');
  }
}
