export default {
  companyAdmin: 'company-admin',
  employeeList: 'company-admin.employee-list',
  employeeBookingsList: 'company-admin.employee-bookings-list',
  employeeBookingRequests: 'company-admin.employee-booking-requests',
  paymentMethods: 'company-admin.payment-methods',
  invoices: 'company-admin.invoices',
  fleetList: 'company-admin.fleet-list',
  bookingOverview: 'company-admin.booking-overview',
  reporting: 'company-admin.reporting',
  settings: 'company-admin.settings',
  tariffs: 'company-admin.tariffs',
};
