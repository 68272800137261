// TODO: Refactor the name of the methods https://europcarmobility.atlassian.net/browse/CBF-958
export class CSOperator {
  constructor(data) {
    this.uuid = data.uuid;
    this.name = data.name;
    this.configuration = data.configuration || {};
    this.customer_service_phone = data.customer_service_phone;
    this.customer_service_email = data.customer_service_email;
    this.customer_service_opening_hours = data.customer_service_opening_hours;
    this.country_code = data.country_code;
    this.help_link = data.help_link;
  }

  getUUID() {
    return this.uuid;
  }

  getConfiguration() {
    return this.configuration;
  }

  getCustomerServicePhone() {
    return this.customer_service_phone;
  }

  isBadgeRequired() {
    return this.configuration.badge_required;
  }

  getIntervalTimeUnit() {
    return parseInt(this.configuration.interval_time_unit, 10);
  }

  getCarsharingDefaultDuration() {
    return parseInt(this.configuration.carsharing_default_duration, 10);
  }

  getCarsharingMinBookingDuration() {
    return parseInt(this.configuration.cs_minimum_booking_duration, 10);
  }

  getCarsharingDefaultMinBookingDuration() {
    const minBookingDuration = this.configuration.default_cs_minimum_booking_duration
      || this.getCarsharingMinBookingDuration();

    return parseInt(minBookingDuration, 10);
  }

  getCarsharingDefaultMaxBookingDuration() {
    const maxBookingDuration = this.configuration.default_cs_maximum_booking_duration
      || this.getCarsharingMaxBookingDuration();

    return parseInt(maxBookingDuration, 10);
  }

  getCarsharingMaxBookingDuration() {
    return parseInt(this.configuration.cs_maximum_booking_duration, 10);
  }

  getCarsharingBookingGap() {
    return parseInt(this.configuration.cs_booking_gap, 10);
  }

  getLongDistanceMinBookingDuration() {
    return parseInt(this.configuration.ld_minimum_booking_duration, 10);
  }

  getLongDistanceDefaultMinBookingDuration() {
    const minBookingDuration = this.configuration.default_ld_minimum_booking_duration
      || this.getLongDistanceMinBookingDuration();

    return parseInt(minBookingDuration, 10);
  }

  getLongDistanceMaxBookingDuration() {
    return parseInt(this.configuration.ld_maximum_booking_duration, 10);
  }

  getLongDistanceDefaultMaxBookingDuration() {
    const maxBookingDuration = this.configuration.default_ld_maximum_booking_duration
    || this.getLongDistanceMaxBookingDuration();

    return parseInt(maxBookingDuration, 10);
  }

  getLongDistanceBookingGap() {
    return parseInt(this.configuration.ld_booking_gap, 10);
  }

  getLongDistanceDefaultDuration() {
    return parseInt(this.configuration.long_distance_default_duration, 10);
  }

  getInterventionDefaultDuration() {
    return parseInt(this.configuration.intervention_default_duration, 10);
  }

  getInterventionMinBookingDuration() {
    return parseInt(this.configuration.in_minimum_booking_duration, 10);
  }

  getInterventionDefaultMinBookingDuration() {
    const minBookingDuration = this.configuration.default_in_minimum_booking_duration
      || this.getInterventionMinBookingDuration();

    return parseInt(minBookingDuration, 10);
  }

  getInterventionDefaultMaxBookingDuration() {
    const maxBookingDuration = this.configuration.default_in_maximum_booking_duration
      || this.getInterventionMaxBookingDuration();

    return parseInt(maxBookingDuration, 10);
  }

  getInterventionMaxBookingDuration() {
    return parseInt(this.configuration.in_maximum_booking_duration, 10);
  }

  getInterventionBookingGap() {
    return parseInt(this.configuration.in_booking_gap, 10);
  }

  setDuration(minDuration, maxDuration) {
    this.configuration.cs_minimum_booking_duration = minDuration;
    this.configuration.default_cs_minimum_booking_duration = minDuration;
    this.configuration.in_minimum_booking_duration = minDuration;
    this.configuration.default_in_minimum_booking_duration = minDuration;
    this.configuration.ld_minimum_booking_duration = minDuration;
    this.configuration.default_ld_minimum_booking_duration = minDuration;

    this.configuration.cs_maximum_booking_duration = maxDuration;
    this.configuration.default_cs_maximum_booking_duration = maxDuration;
    this.configuration.in_maximum_booking_duration = maxDuration;
    this.configuration.default_in_maximum_booking_duration = maxDuration;
    this.configuration.ld_maximum_booking_duration = maxDuration;
    this.configuration.default_ld_maximum_booking_duration = maxDuration;
  }

  static create(attr) {
    return new CSOperator(attr);
  }
}

export default CSOperator;

