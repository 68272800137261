import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';

import { COST_ALLOCATION_TYPE } from '@/constants/costAllocationType.const';
import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';

export const NAMESPACE = 'PROFILE_COST_ALLOCATION';

export const ACTIONS = {
  FETCH_COMPANY_COST_ALLOCATION: 'FETCH_COMPANY_COST_ALLOCATION',
  FETCH_PROFILE_COST_ALLOCATION: 'FETCH_PROFILE_COST_ALLOCATION',
  UPDATE_PROFILE_COST_ALLOCATION: 'UPDATE_PROFILE_COST_ALLOCATION',
};

export const GETTERS = {
  IS_LOADING_STATUS: 'IS_LOADING_STATUS',
};

const getters = {
  [GETTERS.IS_LOADING_STATUS](state) {
    return get(state, `${CostAllocationDataModule.NAMESPACE}.STATUS.LOADING`);
  },
};

const actions = {
  [ACTIONS.FETCH_COMPANY_COST_ALLOCATION]({ commit }, { companyUuid = '' }) {
    return external.companyCostAllocation.getCostAllocations(companyUuid, {
      filter: COST_ALLOCATION_TYPE.profile,
    })
      .then(response => {
        commit(
          CostAllocationDataModule.MUTATIONS.setCostAllocationList,
          get(response, 'data', []),
        );

        return response;
      });
  },

  [ACTIONS.FETCH_PROFILE_COST_ALLOCATION]({ commit }, { profileUuid = '' }) {
    return external.companyCostAllocation.getUserProfileCostAllocations(profileUuid)
      .then(response => {
        commit(
          CostAllocationDataModule.MUTATIONS.setCurrentCostAllcation,
          get(response, 'data', []),
        );

        return response;
      });
  },

  [ACTIONS.UPDATE_PROFILE_COST_ALLOCATION]({ commit, getters: g }, { profileUuid = '' }) {
    const payload = g[CostAllocationDataModule.GETTERS.apiPayloadCurrentCostAllocation];

    commit(
      CostAllocationDataModule.MUTATIONS.setStatus,
      STORE_REQUEST_STATUS.loading,
    );

    return external.companyCostAllocation.postUserProfileCostAllocations(
      profileUuid,
      {
        cost_allocations: payload,
      },
    )
      .then(() => {
        commit(
          CostAllocationDataModule.MUTATIONS.setStatus,
          STORE_REQUEST_STATUS.loaded,
        );
      })
      .catch(e => {
        commit(
          CostAllocationDataModule.MUTATIONS.setStatus,
          STORE_REQUEST_STATUS.idle,
        );
        throw e;
      });
  },
};

export default {
  store: {
    namespaced: true,
    actions,
    getters,
    modules: {
      [CostAllocationDataModule.NAMESPACE]: CostAllocationDataModule.store,
    },
  },
  NAMESPACE,
  ACTIONS,
};
