import getInitialState from './_state';

export const TYPE = {
  LOADER_ACTIVE: 'LOADER_ACTIVE',

  RESET_STATE: 'RESET_STATE',
};

export default {
  [TYPE.LOADER_ACTIVE](state, isActive) {
    return Object.assign(state, {
      isActive,
    });
  },

  [TYPE.RESET_STATE](state) {
    return Object.assign(state, getInitialState());
  },
};
