import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { EXPIRES_IN } from '@emobg/web-utils';

const IS_IMPERSONATE = 'is_impersonate';
const ACCESS_TOKEN = 'impersonate_accessToken';
const IDP_UUID = 'impersonate_idpUUID';
const EXPIRES_AT = 'expiresAt';

/**
 * Get the impersonation data stored in localStorage
 * These data is passed from Backoffice / Backend to WebApp by URL
 * and is stored in localStorage in the /impersonate URL
 * @param localStorage
 * @returns {{idpUuid, accessToken, isImpersonate: boolean, expiresAt}}
 */
export const getImpersonation = localStorage => {
  if (!isFunction(get(localStorage, 'getItem'))) {
    throw new Error('Error getting the data from the Storage');
  }

  return {
    isImpersonate: localStorage.getItem(IS_IMPERSONATE) === 'true',
    accessToken: localStorage.getItem(ACCESS_TOKEN),
    idpUuid: localStorage.getItem(IDP_UUID),
    expiresAt: localStorage.getItem(EXPIRES_AT),
  };
};

/**
 * Return the impersonation data in an object similar to AuthResult got from
 * Auth0 when the #hash in the URL is parsed
 * @param impersonationData
 * @returns {{expiresIn, idTokenPayload: {sub}, accessToken, expiresAt}}
 */
export const fakeAuthResult = impersonationData => {
  const expiresAt = get(impersonationData, 'expiresAt');
  const idpUuid = get(impersonationData, 'idpUuid');
  const accessToken = get(impersonationData, 'accessToken');

  if (!(expiresAt && idpUuid && accessToken)) {
    throw new Error('Impersonation data is required');
  }

  return {
    expiresAt,
    expiresIn: EXPIRES_IN,
    idTokenPayload: {
      sub: idpUuid,
    },
    accessToken,
  };
};
