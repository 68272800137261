const placeholder = '__USER_UUID__';

export const MFA = {
  sendFirstCodeFlagStorageKey: 'send-first-code-flag',
  userDeviceStorageKey: `user-device-token-${placeholder}`,
  userDeviceStorageTokenKey: 'token',
  userUuidStorageKeyPlaceholder: placeholder,
  userDeviceHeaderKey: 'user-device-token',
  responseTokenKey: 'userDeviceToken',
  responseTokenExpirationDate: 'expirationDate',
  mfaRequiredErrorKey: 'authentication.mfa_required',
  lastResentCodeStorageKey: 'lastResentCode',
  extraUserMetadata: 'extra_user_metadata',
  userHasCompany: 'has_company',
  userEmail: 'user_email',
  errorKey: 'error_key',
};
