const getState = () => ({

  profileCollection: undefined,

  currentProfile: undefined,

  currentProfileSelected: undefined,

});

export default getState;
