import routes from './routes-names';

const PersonalProfile = () => import(/* webpackChunkName: "personal" */'../PersonalProfileView');

export const PersonalProfileRoute = {
  path: '/personal-profile',
  name: routes.personalProfile,
  redirect: {
    name: routes.insurances,
  },
};

export const PaymentMethodsRoute = {
  path: '/personal-profile/payment-methods',
  name: routes.paymentMethods,
  component: PersonalProfile,
  meta: {
    requireAuth: true,
  },
};

export const InvoicesRoute = {
  path: '/personal-profile/invoices',
  name: routes.invoices,
  component: PersonalProfile,
  meta: {
    requireAuth: true,
  },
};

export const TariffsRoute = {
  path: '/personal-profile/tariffs',
  name: routes.tariffs,
  component: PersonalProfile,
  meta: {
    requireAuth: true,
  },
};

export const InsurancesRoute = {
  path: '/personal-profile/insurances',
  name: routes.insurances,
  component: PersonalProfile,
  meta: {
    requireAuth: true,
  },
};

export const PromotionsRoute = {
  path: '/personal-profile/promotions',
  name: routes.promotions,
  component: PersonalProfile,
  meta: {
    requireAuth: true,
  },
};

export default [
  PersonalProfileRoute,
  PaymentMethodsRoute,
  InvoicesRoute,
  TariffsRoute,
  InsurancesRoute,
  PromotionsRoute,
];
